import { Container } from '@mui/material';

import { useEpisodes } from 'api';
import {
  HorizontalScroll,
  HorizontalScrollLoading,
  HorizontalScrollTitle,
} from 'components/scroll';
import { HorizontalScrollTitleLoading } from 'components/scroll/loading';
import { Episode } from 'models';
import { ROUTE_CONSTANTS } from 'routes';
import { theme } from 'styles/theme';

import { EpisodeCard } from '../EpisodeCard';

type EpisodeDetailsRelatedProps = {
  episode?: Episode;
};

export const EpisodeDetailsRelated = ({ episode }: EpisodeDetailsRelatedProps) => {
  const { data: episodes = [], isLoading } = useEpisodes(episode?.podcastId ?? '');

  if (isLoading || !episode)
    return (
      <Container sx={{ mb: theme.variables.container.mb }}>
        <HorizontalScrollTitleLoading />
        <HorizontalScrollLoading />
      </Container>
    );

  // No other episodes in this podcast
  if (episodes.length === 1) return null;

  return (
    <Container sx={{ mb: theme.variables.container.mb }}>
      <HorizontalScrollTitle
        title={`Mehr Episoden von ${episode.podcastName}`}
        link={`${ROUTE_CONSTANTS.PODCAST}/${episode.podcastId}`}
      />
      <HorizontalScroll>
        {episodes
          .filter((e) => e.id !== episode.id)
          .map((episode) => (
            <EpisodeCard key={episode.id} episode={episode} />
          ))}
      </HorizontalScroll>
    </Container>
  );
};
