import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { Episode, EpisodeTimestamp, EpisodeTimestampsStorage } from 'models';
import { timestampState } from 'store/timestamp';
import { LOCAL_STORAGE_CONSTANTS, storagePrefix } from 'utils';

import { useLocalStorage } from './useLocalStorage';

export const useEpisodeTimestamp = (episodeId: Episode['id']) => {
  const [episodeTimestamps, setEpisodeTimestamps] = useLocalStorage<EpisodeTimestampsStorage>(
    `${storagePrefix}${LOCAL_STORAGE_CONSTANTS.EPISODE_TIMESTAMP}`,
    {}
  );

  const [timestamps, setTimestamps] = useRecoilState(timestampState);

  useEffect(() => {
    setTimestamps(episodeTimestamps);
  }, [episodeTimestamps, setTimestamps]);

  const setEpisodeTimestamp = (timestamp: EpisodeTimestamp) => {
    setEpisodeTimestamps((prevData) => ({
      ...prevData,
      [episodeId]: timestamp,
    }));
  };

  return { episodeTimestamp: timestamps[episodeId], setEpisodeTimestamp };
};
