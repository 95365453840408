/**
 * The maximum duration (in seconds) for a podcast episode to be considered "short".
 * Podcasts shorter than this duration will have a lower threshold for determining
 * when they are finished playing.
 *
 * @type {number}
 */
export const SHORT_EPISODE_DURATION = 150;

/**
 * The threshold (in seconds) for determining when a podcast episode is finished playing.
 * Podcasts longer than SHORT_EPISODE_DURATION will use this threshold.
 *
 * @type {number}
 */
export const FINISHED_THRESHOLD = 30;

/**
 * The threshold (in seconds) for determining when a "short" podcast episode is finished playing.
 * Podcasts shorter than SHORT_EPISODE_DURATION will use this threshold.
 *
 * @type {number}
 */
export const FINISHED_THRESHOLD_SHORT = 10;

/**
 * Determines whether a podcast episode has finished playing based on its duration
 * and the current play time.
 *
 * @param {number} episodeDuration - The duration (in seconds) of the podcast episode.
 * @param {number} currentPlayTime - The current play time (in seconds).
 * @returns {boolean} - True if the episode has finished playing, false otherwise.
 */
export const isEpisodeFinished = (episodeDuration: number, currentPlayTime: number) => {
  if (
    isNaN(episodeDuration) ||
    isNaN(currentPlayTime) ||
    episodeDuration < 0 ||
    currentPlayTime < 0
  ) {
    return false;
  }

  const remainingTime = episodeDuration - currentPlayTime;

  if (episodeDuration < SHORT_EPISODE_DURATION) {
    return remainingTime <= FINISHED_THRESHOLD_SHORT;
  }

  return remainingTime <= FINISHED_THRESHOLD;
};
