import { Button } from '@mui/material';

import { ROUTE_CONSTANTS } from 'routes';

import { environment } from '../../environments';
import useMsal from '../../lib/authConfig';

const { msal } = environment;

export const LogoutButton = () => {
  const { instance, accounts } = useMsal();

  const handleLogout = () => {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: window.location.origin,
    };

    if (msal.active) {
      instance?.logoutRedirect(logoutRequest);
    }
    location.href = ROUTE_CONSTANTS.DISCOVER;
  };

  return (
    <Button variant='contained' onClick={handleLogout} fullWidth sx={{ textTransform: 'none' }}>
      Abmelden
    </Button>
  );
};
