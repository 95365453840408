import { IconButton, IconButtonTypeMap, SxProps } from '@mui/material';

import { Episode } from 'models';

import { PlayButtonIcons } from './PlayButtonIcons';

type PlayButtonProps = {
  episodeId: Episode['id'];
  color?: IconButtonTypeMap['props']['color'];
  sx?: SxProps;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const PlayButton = ({ episodeId, color = 'default', sx, onClick }: PlayButtonProps) => {
  return (
    <IconButton color={color} sx={{ width: '2.75rem', height: '2.75rem', ...sx }} onClick={onClick}>
      <PlayButtonIcons episodeId={episodeId} />
    </IconButton>
  );
};
