import { Typography } from '@mui/material';
import { Fragment } from 'react';

import { GridContainer } from 'components/common';
import { PodcastCard } from 'components/podcast/PodcastCard';
import { Podcast } from 'models';

type CategoryPodcastsProps = {
  podcasts: Podcast[];
};

export const CategoryPodcasts = ({ podcasts }: CategoryPodcastsProps) => {
  return (
    <Fragment>
      <Typography variant='h3' sx={{ mb: '2rem' }}>
        {podcasts.length} Podcasts
      </Typography>
      <GridContainer>
        {podcasts.map((podcast) => (
          <PodcastCard key={podcast.id} podcast={podcast} />
        ))}
      </GridContainer>
    </Fragment>
  );
};
