import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { ReactNode, SyntheticEvent, useState } from 'react';

import { StyledBgContainer } from 'styles/components';

import { DiscoverLatestEpisodes } from './DiscoverLatestEpisodes';

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`episodes-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `episodes-tab-${index}`,
    'aria-controls': `episodes-tabpanel-${index}`,
  };
}

export const DiscoverEpisodes = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledBgContainer bgcolor='white'>
      <Container>
        <Typography fontWeight='bold' fontSize={32} variant='h4'>
          Episoden
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab
            sx={{ fontSize: 18, padding: 0 }}
            label='Neu erschienen'
            {...a11yProps(0)}
            disableRipple
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <DiscoverLatestEpisodes />
        </TabPanel>
      </Container>
    </StyledBgContainer>
  );
};
