import { Button, Slider, Stack, styled, Typography, TypographyProps } from '@mui/material';
import { MouseEvent } from 'react';

import ReplayIcon from 'assets/icon/replay.svg';
import ResetIcon from 'assets/icon/reset.svg';
import { PlayButton } from 'components/common';
import { Player } from 'models';
import { theme } from 'styles/theme';
import { excludeProps, formatPlaybackTime } from 'utils';

const StyledTypography = styled(({ children, ...props }: TypographyProps) => (
  <Typography variant='body2' {...props}>
    {children}
  </Typography>
))`
  font-weight: 100;
`;

type StyledPlayControlProps = {
  image: string;
};

const StyledPlayControl = styled(StyledTypography, {
  shouldForwardProp: (prop) => excludeProps<StyledPlayControlProps>(['image'], prop),
})<StyledPlayControlProps>(
  ({ image }) => `
  position: relative;
  font-size: 0.7rem;
  padding: 1rem;
  cursor: pointer;
  :after {
    content: '';
    position: absolute;
    mask-image: url(${image});
    mask-repeat: no-repeat;
    mask-size: 40px;
    background-color: white;
    width: 40px;
    height: 40px;
    left: 0;
    top: 0;
    mask-position: 3px 2px;
  }
  :hover {
    color: ${theme.palette.primary.main};
    :after {
      background-color: ${theme.palette.primary.main};
    }
  }
`
);

type PlayerControlsProps = {
  player: Player;
  playbackRate: number;
  duration: number;
  nextPlaybackRate: () => void;
  onPlayPause: () => void;
  onSeekUp: (event: React.SyntheticEvent | Event, value: number | Array<number>) => void;
  onSeekChange: (event: Event, value: number | Array<number>, activeThumb: number) => void;
};

export const PlayerControls = ({
  player,
  playbackRate,
  duration,
  nextPlaybackRate,
  onPlayPause,
  onSeekUp,
  onSeekChange,
}: PlayerControlsProps) => {
  const handleSkip = (e: MouseEvent<HTMLSpanElement>) => {
    onSeekUp(e, (player.progress.played + 15 / duration) * 100);
  };

  const handleReplay = (e: MouseEvent<HTMLSpanElement>) => {
    onSeekUp(e, (player.progress.played - 15 / duration) * 100);
  };

  return (
    <Stack alignItems='center'>
      <Stack direction='row' alignItems='center' position='relative'>
        <Button
          disableRipple
          onClick={() => nextPlaybackRate()}
          sx={{
            color: 'white',
            fontWeight: '600',
            position: 'absolute',
            left: '-3.5rem',
            ':hover': {
              backgroundColor: 'transparent',
              color: theme.palette.primary.main,
            },
          }}
        >
          {playbackRate}x
        </Button>
        <StyledPlayControl image={ReplayIcon} onClick={(e) => handleReplay(e)}>
          15
        </StyledPlayControl>
        <PlayButton
          episodeId={player.nowPlayingId ?? ''}
          color='secondary'
          onClick={onPlayPause}
          sx={{ mx: '0.5rem', backgroundColor: theme.palette.grey[800] }}
        />
        <StyledPlayControl image={ResetIcon} onClick={(e) => handleSkip(e)}>
          15
        </StyledPlayControl>
      </Stack>

      <Stack direction='row' alignItems='center' width='100%'>
        <StyledTypography>{formatPlaybackTime(player.progress.playedSeconds)}</StyledTypography>
        <Slider
          aria-label='Volume'
          value={player.progress.played * 100}
          onChange={onSeekChange}
          onChangeCommitted={onSeekUp}
          sx={{ mx: '0.5rem', pb: { xs: '10px' }, pt: { xs: '15px' } }}
          min={0}
          max={99}
        />
        <StyledTypography>{formatPlaybackTime(duration)}</StyledTypography>
      </Stack>
    </Stack>
  );
};
