import { Typography } from '@mui/material';

import { HeadingContainer } from 'components/layout';

export const BookmarkHeader = () => {
  return (
    <HeadingContainer>
      <Typography variant='h1'>Gemerkt</Typography>
    </HeadingContainer>
  );
};
