import { useQuery } from 'react-query';

import { queryClient, queryKeys } from 'lib/react-query';
import { getPodcastApp } from 'proxy';
import { toEpisode } from 'services';

export const getAllEpisodes = () => getPodcastApp().getContentfulEpisodesByPodcastUsingGET();

export const useAllEpisodes = () => {
  return useQuery(queryKeys.episode.all, () => getAllEpisodes(), {
    initialData: queryClient.getQueryData(queryKeys.episode.all),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.episode.all)?.dataUpdatedAt,
    select: (data) => data?.data && data.data.map(toEpisode),
  });
};
