import {
  Badge,
  BadgeProps,
  MenuItem,
  Select,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';

import { useSearchFilter } from 'hooks';
import { FullTextSearch } from 'models';
import { theme } from 'styles/theme';
import { getUniqueItems } from 'utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: theme.variables.radius.base,
      padding: '0 0.5rem 0 0.5rem',
    },
  },
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  unset: 'all',
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
  },
});

const StyledBadge = styled(Badge)<BadgeProps>({
  '& .MuiBadge-badge': {
    right: 47,
    top: 9,
  },
});

const StyledMenuItem = styled(MenuItem)({
  borderRadius: theme.variables.radius.inner,
  transition: 'all 0.2s ease-in-out',
  '&:not(:last-of-type)': { marginBottom: '0.5rem' },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

type SearchFilterProps = {
  results: FullTextSearch[];
};

export const SearchFilter = ({ results }: SearchFilterProps) => {
  const { filter, handleFilterTypeChange, handleFilterAuthorChange } = useSearchFilter(results);

  return (
    <Fragment>
      <StyledToggleButtonGroup
        value={filter.type}
        exclusive
        onChange={handleFilterTypeChange}
        aria-label='filter'
      >
        <ToggleButton value={'all'} aria-label='filter all'>
          Alles
        </ToggleButton>
        <ToggleButton value='podcast' aria-label='filter podcast'>
          Podcasts
        </ToggleButton>
        <ToggleButton value='episode' aria-label='filter episode'>
          Episoden
        </ToggleButton>
      </StyledToggleButtonGroup>
      <Select
        sx={{
          borderRadius: '16px',
          fontSize: '14px',
          fontWeight: 600,
          height: '32px',
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,
          '& svg': {
            fill: theme.palette.common.white,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[300],
          },
        }}
        disabled={filter.type === 'podcast'}
        multiple
        displayEmpty
        value={filter.authors}
        onChange={handleFilterAuthorChange}
        renderValue={(selected) => (
          <StyledBadge color='primary' badgeContent={selected.length}>
            <Typography
              sx={{ ...(selected.length && { ml: 2.5 }), fontWeight: 'bold', fontSize: '0.8rem' }}
            >
              Autor
            </Typography>
          </StyledBadge>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: 40,
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          ...MenuProps,
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem disabled value=''>
          Autor
        </MenuItem>
        {getUniqueItems(
          results.filter((result) => result?.author).map((result) => result.author) as string[]
        ).map((author, index) => (
          <StyledMenuItem key={`${author}-${index}`} value={author}>
            {author}
          </StyledMenuItem>
        ))}
      </Select>
    </Fragment>
  );
};
