import { useContext } from 'react';

/**
 * Returns the context value or throws an error if the context is undefined.
 *
 * @template T - The type of the context value.
 * @param {React.Context<T | undefined>} context - The context object to get the value from.
 * @returns {T} - The context value.
 * @throws {Error} - If the context value is undefined.
 */
export const useSafeContext = <T,>(context: React.Context<T | undefined>): T => {
  const value = useContext(context);
  if (value === undefined) {
    throw new Error(`Attempted to use ${context.displayName || 'a context'} that was not provided`);
  }
  return value;
};
