/**
 * Converts a length value in rem units to pixels.
 * @param remValue The length value in rem units, as a string or number.
 * @returns The equivalent length value in pixels, as a number.
 */
export const remToPx = (remValue: string | number): number => {
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const pixelValue = parseFloat(remValue.toString()) * rootFontSize;
  return Number(pixelValue.toFixed(0));
};
