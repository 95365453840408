import { Skeleton, Typography } from '@mui/material';
import { Fragment } from 'react';

import { NavigateBackButton } from 'components/common';
import { HeadingContainer } from 'components/layout';
import { ROUTE_CONSTANTS } from 'routes';

type PodcastHeaderProps = {
  heading: string;
  categoryId: string;
  categoryName: string;
  isLoading: boolean;
};

export const PodcastHeader = ({
  heading,
  categoryId,
  categoryName,
  isLoading,
}: PodcastHeaderProps) => {
  return (
    <Fragment>
      {isLoading ? (
        <HeadingContainer>
          <Skeleton sx={{ width: '6rem', height: '2.25rem', bgcolor: 'grey.600' }} />
          <Typography variant='h2' fontSize={40}>
            <Skeleton sx={{ bgcolor: 'grey.600', width: '12rem' }} />
          </Typography>
        </HeadingContainer>
      ) : (
        <HeadingContainer>
          <NavigateBackButton
            title={categoryName}
            link={`${ROUTE_CONSTANTS.CATEGORY}/${categoryId}`}
            state={{ name: categoryName }}
          />
          <Typography variant='h1'>{heading}</Typography>
        </HeadingContainer>
      )}
    </Fragment>
  );
};
