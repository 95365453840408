/**
 * Formats a number representing time to a string in the format of two digits, padded with leading zeros if necessary.
 *
 * @param {number | string} time - The time to format in seconds.
 * @returns {string} The formatted time.
 */
export const formatTime = (time: number | string) => time.toString().padStart(2, '0');

/**
 * Formats the given number of seconds as a playback time in hours, minutes, and seconds.
 *
 * @param {number} seconds - The number of seconds to format.
 * @param {boolean} [shortFormat=true] - Whether to use the short format (default) or the long format.
 * @returns {string} The formatted playback time.
 */
export const formatPlaybackTime = (seconds: number, shortFormat = true) => {
  if (seconds < 0) return '';

  const SECONDS_IN_HOUR = 3600;

  const hours = Math.floor(seconds / SECONDS_IN_HOUR);
  const minutes = Math.floor((seconds % SECONDS_IN_HOUR) / 60);
  const remainingSeconds = Math.floor(seconds % 60).toFixed(0);

  if (shortFormat) {
    const formattedTime = `${formatTime(minutes)}:${formatTime(remainingSeconds)}`;
    const formattedTimeWithHours = `${hours}:${formattedTime}`;
    return hours > 0 ? formattedTimeWithHours : formattedTime;
  } else {
    const formattedSec = `${remainingSeconds} Sek.`;
    const formattedMin = `${formatTime(minutes)} Min.`;
    const formattedTime = `${formattedMin} ${formattedSec}`;
    const formattedTimeWithHours = `${hours} Std. ${formattedTime}`;
    return seconds < 60 ? formattedSec : hours > 0 ? formattedTimeWithHours : formattedTime;
  }
};
