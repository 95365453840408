import { alpha, Box, Container, Grid, Stack, styled } from '@mui/material';
import { useRef } from 'react';
import ReactPlayer from 'react-player';

import { useEpisode } from 'api';
import { EpisodeActions } from 'components/episode';
import { usePlayer } from 'hooks/usePlayer';
import { theme } from 'styles/theme';

import { PlayerControls } from './PlayerControls';
import { PlayerEpisodeInfo } from './PlayerEpisodeInfo';
import { VolumeSlider } from './VolumeSlider';

const StyledPlayerWrapper = styled(Box)`
  position: fixed;
  z-index: 99;
  bottom: 0;
  background-color: ${alpha(theme.palette.secondary.main, 0.95)};
  backdrop-filter: blur(10px);
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
`;

export const PlayerBar = () => {
  const playerRef = useRef<ReactPlayer>(null);

  const {
    player,
    playbackRate,
    volume,
    handleIsReady,
    nextPlaybackRate,
    handlePlayPause,
    handleProgress,
    handleSeekChange,
    handleSeekUp,
    handleBufferStart,
    handleBufferEnd,
    handleVolumeChange,
    handleVolumeOn,
    handleVolumeOff,
  } = usePlayer(playerRef);

  const { data: episode, isLoading } = useEpisode(player.nowPlayingId ?? '');

  if (!player.nowPlayingId) return null;

  if (!isLoading && !episode) return <>No episode found</>;

  return (
    <StyledPlayerWrapper>
      <Container sx={{ py: 2 }}>
        <Grid container direction='row' alignItems='center' gap={1} flexWrap={{ sm: 'nowrap' }}>
          <Grid item xs={12} sm={4} md={4} direction='row'>
            <PlayerEpisodeInfo isLoading={isLoading} episode={episode} />
          </Grid>
          <Grid item xs={12} sm={4} md={4} alignItems='center' justifyContent='center'>
            <ReactPlayer
              ref={playerRef}
              url={episode?.audio}
              playing={player.isPlaying}
              playbackRate={playbackRate}
              volume={volume / 100}
              config={{ file: { forceAudio: true } }}
              onReady={handleIsReady}
              onProgress={(state) => episode && handleProgress(state, episode)}
              onBuffer={handleBufferStart}
              onBufferEnd={handleBufferEnd}
              onEnded={handlePlayPause}
              width='0'
              height='0'
            />
            <PlayerControls
              player={player}
              playbackRate={playbackRate}
              duration={playerRef.current?.getDuration() ?? 0}
              nextPlaybackRate={nextPlaybackRate}
              onPlayPause={handlePlayPause}
              onSeekChange={handleSeekChange}
              onSeekUp={handleSeekUp}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} alignItems='center'>
            <Stack
              spacing={2}
              direction='row'
              alignItems='center'
              justifyContent={{ xs: 'center', sm: 'end' }}
              flexWrap='wrap'
            >
              <VolumeSlider
                volume={volume}
                handleVolumeChange={handleVolumeChange}
                handleVolumeOff={handleVolumeOff}
                handleVolumeOn={handleVolumeOn}
              />
              <EpisodeActions episodeId={player.nowPlayingId} />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </StyledPlayerWrapper>
  );
};
