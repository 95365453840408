import { useQuery } from 'react-query';

import { queryKeys } from 'lib/react-query';
import { GetContentfulFullTextSearchUsingGETParams, getPodcastApp } from 'proxy';
import { toSearch } from 'services';

export const getSearch = (params: GetContentfulFullTextSearchUsingGETParams) =>
  getPodcastApp().getContentfulFullTextSearchUsingGET(params);

export const useSearch = (search: string) => {
  return useQuery(queryKeys.search.list(search), () => getSearch({ text: search }), {
    select: (data) => data?.data && data?.data.map(toSearch),
    enabled: !!search,
  });
};
