import { Box, CircularProgress, List, Stack, Typography } from '@mui/material';

import { useSearchFilter } from 'hooks';
import { FullTextSearch } from 'models';

import { SearchFilter } from './SearchFilter';
import { SearchResultItem } from './SearchResultItem';

type SearchResultsProps = {
  results: FullTextSearch[];
  search: string;
  isLoading?: boolean;
};

export const SearchResults = ({ results, search, isLoading = false }: SearchResultsProps) => {
  const { filteredResults } = useSearchFilter(results);

  if (isLoading)
    return (
      <Stack alignItems='center'>
        <CircularProgress />
      </Stack>
    );

  return (
    <Stack spacing={4}>
      <Box>
        <Typography variant='h4' fontSize={14} mb={1}>
          {search.length > 0 ? `${filteredResults.length} Ergebnisse` : `Bitte neue Suche starten`}
        </Typography>
        {results.length ? (
          <Stack direction='row'>
            <SearchFilter results={results} />
          </Stack>
        ) : null}
      </Box>
      <List>
        {filteredResults.map((result) => (
          <SearchResultItem key={result.id} {...result} />
        ))}
      </List>
    </Stack>
  );
};
