import { CloseRounded } from '@mui/icons-material';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';

import { ReactComponent as BroadcastIcon } from 'assets/icon/broadcast.svg';
import waves from 'assets/img/waves.svg';
import { useModal } from 'hooks/useModal';
import { ROUTE_CONSTANTS } from 'routes';
import { theme } from 'styles/theme';

export type OnboardModalProps = {
  size?: false | Breakpoint;
  handleClose?: () => void;
};

const StyledContent = styled(DialogContent)(
  ({ theme }) => `
  padding: 4rem;
  background: url(${waves});
  background-repeat: no-repeat;
  background-position: right;
  background-color: ${theme.palette.secondary.main};
  color: white;
  border-radius: ${theme.variables.radius.base};
`
);

export const OnboardModal = ({ handleClose, size = 'md' }: OnboardModalProps) => {
  const navigate = useNavigate();
  const { hideModal } = useModal();

  const onClose = () => {
    handleClose && handleClose();
    hideModal();
  };

  const handleDiscover = () => {
    onClose();
    navigate(ROUTE_CONSTANTS.DISCOVER);
  };

  return (
    <Dialog
      keepMounted={false}
      open
      onClose={onClose}
      fullWidth
      maxWidth={size}
      disableEscapeKeyDown
      sx={{
        backdropFilter: 'blur(5px)',
      }}
      PaperProps={{
        sx: {
          overflow: 'visible',
          borderRadius: theme.variables.radius.base,
        },
      }}
    >
      <IconButton
        onClick={onClose}
        color='primary'
        sx={{
          backgroundColor: 'white',
          color: 'black',
          position: 'absolute',
          right: '-1rem',
          top: '-1rem',
          ':hover': {
            color: 'white',
          },
        }}
      >
        <CloseRounded />
      </IconButton>
      <StyledContent>
        <Stack spacing={4}>
          <Typography variant='h2' fontSize={28} lineHeight='2rem' fontWeight='bold'>
            Herzlich Willkommen
            <br />
            bei Podcast@Porsche
          </Typography>
          <Typography variant='h3' fontSize={16}>
            Deine Podcast-App für Themen die uns bei Porsche bewegen
          </Typography>
          <Stack direction='row' spacing={4}>
            <Stack direction='column'>
              <Typography fontWeight='bold'>Für wen und was?</Typography>
              <Typography>
                Diese App richtet sich an Porsche Mitarbeiter, die sich mittels Podcast weiterbilden
                oder über aktuelle Themen informieren wollen.
              </Typography>
            </Stack>
            <Stack direction='column'>
              <Typography fontWeight='bold'>Von wem?</Typography>
              <Typography>
                In Podcasts aus den verschiedenen Ressorts geben Mitarbeiterinnen und Mitarbeiter
                authentische und tiefe Einblicke in ihre Themen.
              </Typography>
            </Stack>
          </Stack>
          <Button
            size='large'
            sx={{ color: 'white', width: 200, alignSelf: 'end' }}
            variant='contained'
            startIcon={<BroadcastIcon fill='white' />}
            onClick={handleDiscover}
          >
            Podcasts entdecken
          </Button>
        </Stack>
      </StyledContent>
    </Dialog>
  );
};
