import {
  Chip,
  Divider,
  ListItem,
  ListItemText,
  Stack,
  styled,
  SvgIcon,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as UserIcon } from 'assets/icon/user.svg';
import { FullTextSearch } from 'models';
import { ROUTE_CONSTANTS } from 'routes';
import { StyledPre } from 'styles/components';
import { theme } from 'styles/theme';

const StyledSearchLink = styled(NavLink)`
  text-decoration: none;
  border-radius: ${theme.variables.radius.base};
  display: block;
  transition: all 0.2s ease-in-out;
  border: 2px solid transparent;
  background-color: ${theme.palette.background.default};
  :hover {
    background-color: ${theme.palette.background.paper};
    transform: translate(0, -5px);
  }
`;

export const SearchResultItem = ({
  author,
  description,
  content_type,
  title,
  id,
}: FullTextSearch) => {
  return (
    <Fragment>
      <StyledSearchLink
        to={`${
          content_type === 'podcast' ? ROUTE_CONSTANTS.PODCAST : ROUTE_CONSTANTS.EPISODE
        }/${id}`}
      >
        <ListItem alignItems='flex-start' sx={{ my: 1 }}>
          <ListItemText
            primary={
              <Fragment>
                <Typography fontSize={16} fontWeight={600} sx={{ color: 'black' }}>
                  {title}
                </Typography>
                <Stack direction='row' spacing={1} sx={{ my: 1 }}>
                  <Chip
                    size='small'
                    sx={{ textTransform: 'uppercase', fontWeight: '700', pointerEvents: 'none' }}
                    label={content_type}
                    color={content_type === 'podcast' ? 'secondary' : 'primary'}
                  />
                  {content_type === 'episode' && (
                    <Chip
                      size='small'
                      label={
                        <Stack direction='row' alignItems='center' sx={{ mx: 0.5 }}>
                          <SvgIcon component={UserIcon} sx={{ width: '20px', mr: 0.5 }} />
                          <Typography variant='body2' sx={{ fontWeight: 'normal' }}>
                            {author}
                          </Typography>
                        </Stack>
                      }
                      sx={{ mt: 1 }}
                    />
                  )}
                </Stack>
              </Fragment>
            }
            secondary={
              <Fragment>
                <Typography sx={{ display: 'inline' }} component='span' variant='h5' fontSize={14}>
                  <StyledPre>{description}</StyledPre>
                </Typography>
              </Fragment>
            }
          />
        </ListItem>
      </StyledSearchLink>
      <Divider />
    </Fragment>
  );
};
