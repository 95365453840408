import {
  alpha,
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  SvgIcon,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { useHover } from 'ahooks';
import { Fragment, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as BookmarkIconFilled } from 'assets/icon/bookmark-filled.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icon/bookmark.svg';
import { ReactComponent as BroadcastIconFilled } from 'assets/icon/broadcast-filled.svg';
import { ReactComponent as BroadcastIcon } from 'assets/icon/broadcast.svg';
import { ReactComponent as GridIconFilled } from 'assets/icon/grid-filled.svg';
import { ReactComponent as GridIcon } from 'assets/icon/grid.svg';
import { ReactComponent as MenuIcon } from 'assets/icon/menu.svg';
import { ReactComponent as SearchIcon } from 'assets/icon/search.svg';
import { ReactComponent as PodcastLogo } from 'assets/img/podcast-logo.svg';
import { LibraryMenu } from 'components/common';
import { useUser } from 'lib';
import { ROUTE_CONSTANTS } from 'routes';
import { theme } from 'styles/theme';
import { getInitials } from 'utils';

import { HeaderMenu } from './HeaderMenu';
import { HeaderMenuCategories } from './HeaderMenuCategories';
import { HeaderMobileMenu } from './HeaderMobileMenu';
import { NavigationButton, StyledNavItem } from './NavigationButton';

// TODO: Add user details
export const Header = () => {
  const ref = useRef(null);
  const isHovering = useHover(ref);
  const isMediaSm = useMediaQuery(theme.breakpoints.down('sm'));
  const username = useUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElLibrary, setAnchorElLibrary] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLibraryMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLibrary(event.currentTarget);
  };
  const handleLibraryMenuClose = () => {
    setAnchorElLibrary(null);
  };

  return (
    <Fragment>
      <AppBar
        position='fixed'
        sx={{
          backgroundColor: alpha(theme.palette.background.paper, 0.9),
          backdropFilter: 'blur(5px)',
          zIndex: 999,
        }}
        enableColorOnDark
      >
        <Container>
          <Toolbar
            sx={{ height: theme.variables.header.height, justifyContent: 'space-between' }}
            disableGutters
          >
            <NavLink to={ROUTE_CONSTANTS.DISCOVER} style={{ display: 'flex' }}>
              <SvgIcon
                component={PodcastLogo}
                inheritViewBox
                sx={{ fontSize: { xs: '40px', sm: '56px' }, cursor: 'pointer' }}
              />
            </NavLink>

            {!isMediaSm && (
              <Box display='flex'>
                <NavigationButton
                  title='Entdecken'
                  startIcon={BroadcastIcon}
                  activeStartIcon={BroadcastIconFilled}
                  link={ROUTE_CONSTANTS.DISCOVER}
                  sx={{ fontWeight: 'bold' }}
                />
                <StyledNavItem
                  ref={ref}
                  disableRipple
                  onClick={handleLibraryMenuClick}
                  sx={{ fontWeight: 'bold' }}
                >
                  <SvgIcon
                    component={isHovering ? GridIconFilled : GridIcon}
                    sx={{ mr: 1 }}
                  ></SvgIcon>
                  Bibliothek
                </StyledNavItem>
                <NavigationButton
                  title='Gemerkt'
                  link={ROUTE_CONSTANTS.BOOKMARK}
                  startIcon={BookmarkIcon}
                  activeStartIcon={BookmarkIconFilled}
                  sx={{ fontWeight: 'bold' }}
                />
                <NavigationButton
                  title='Suchen'
                  link={ROUTE_CONSTANTS.SEARCH}
                  startIcon={SearchIcon}
                  sx={{ fontWeight: 'bold' }}
                />
              </Box>
            )}
            <Box>
              {isMediaSm ? (
                <IconButton
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleMenuClick} sx={{ p: 0 }}>
                  <Avatar sx={{ backgroundColor: theme.palette.grey[800] }}>
                    {getInitials(username ?? '')}
                  </Avatar>
                </IconButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {isMediaSm ? (
        <HeaderMobileMenu anchorEl={anchorEl} handleClose={handleMenuClose} />
      ) : (
        <HeaderMenu anchorEl={anchorEl} handleClose={handleMenuClose} />
      )}
      <LibraryMenu
        title='Kategorien'
        anchorEl={anchorElLibrary}
        handleClose={handleLibraryMenuClose}
      >
        <HeaderMenuCategories />
      </LibraryMenu>
    </Fragment>
  );
};
