import { Button, Skeleton, Stack, SvgIcon, Typography } from '@mui/material';
import { Fragment } from 'react';

import { ReactComponent as SortIcon } from 'assets/icon/sort.svg';
import { EpisodeInfoCard } from 'components/episode';
import { useSortByDate } from 'hooks';
import { Episode } from 'models';
import { theme } from 'styles/theme';

type PodcastEpisodesProps = {
  episodes?: Episode[];
  isLoading: boolean;
};

export const PodcastEpisodes = ({ episodes, isLoading }: PodcastEpisodesProps) => {
  const {
    sortedArr: sortedEpisodes,
    sortDirection,
    toggleDirection,
  } = useSortByDate({
    arr: episodes ?? [],
    sortProp: 'createdAt',
  });

  const isLatestEpisode = (episodeId: string) => {
    return sortDirection === 'asc'
      ? sortedEpisodes[sortedEpisodes.length - 1].id === episodeId
      : sortedEpisodes[0].id === episodeId;
  };

  if (isLoading)
    return (
      <Fragment>
        <Stack justifyContent='space-between' direction='row'>
          <Typography variant='h3' sx={{ mb: '2rem' }}>
            <Skeleton width='8rem' />
          </Typography>
          <Skeleton width='8rem' height='2.25rem' />
        </Stack>
        <Skeleton
          variant='rectangular'
          height='15rem'
          sx={{ borderRadius: theme.variables.radius.base }}
        />
      </Fragment>
    );

  if (!episodes) return <></>;

  return (
    <Fragment>
      <Stack justifyContent='space-between' direction='row'>
        <Typography variant='h3' sx={{ mb: '2rem' }}>
          {episodes.length} Episoden
        </Typography>
        <Button
          sx={{ height: 'fit-content', color: theme.palette.text.primary }}
          onClick={() => toggleDirection()}
        >
          <SvgIcon component={SortIcon} sx={{ mr: '0.5rem' }} />
          {sortDirection === 'asc' ? 'Älteste zuerst' : 'Neuste Zuerst'}
        </Button>
      </Stack>
      {sortedEpisodes.map((episode) => (
        <EpisodeInfoCard
          key={episode.id}
          episode={episode}
          isLatest={isLatestEpisode(episode.id)}
        />
      ))}
    </Fragment>
  );
};
