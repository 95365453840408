import { ReactComponent as ArrowHeadLeft } from 'assets/icon/arrow-head-left.svg';
import { NavigationButton } from 'components/header';

type NavigatebackButtonProps = {
  title: string;
  link: string;
  state?: any;
};

export const NavigateBackButton = ({ title, link, state }: NavigatebackButtonProps) => {
  return (
    <NavigationButton
      title={title}
      link={link}
      startIcon={ArrowHeadLeft}
      sx={{
        pl: 0,
        color: 'white',
        fill: 'white',
        width: 'fit-content',
        display: 'inline-flex',
      }}
      state={state}
    />
  );
};
