import { Fragment } from 'react';

import { DiscoverCategories, DiscoverEpisodes, DiscoverHeader } from 'components/discover';

export const Discover = () => {
  return (
    <Fragment>
      <DiscoverHeader />
      <DiscoverEpisodes />
      <DiscoverCategories />
    </Fragment>
  );
};
