import { Slider, Stack, SvgIcon, Typography, TypographyProps } from '@mui/material';
import { Fragment } from 'react';

import { ReactComponent as CheckIcon } from 'assets/icon/check.svg';
import { EpisodeTimestamp } from 'models';
import { theme } from 'styles/theme';
import { formatPlaybackTime, isEpisodeFinished } from 'utils';

type EpisodeDurationInfoProps = {
  lengthSeconds: number;
  hasPlayed?: EpisodeTimestamp;
  textVariant?: TypographyProps['variant'];
  shortFormat?: boolean;
};

export const EpisodeDurationInfo = ({
  lengthSeconds,
  hasPlayed,
  textVariant = 'body1',
  shortFormat = false,
}: EpisodeDurationInfoProps) => {
  const renderPlaybackTime = () => {
    if (isEpisodeFinished(lengthSeconds, hasPlayed?.playedSeconds ?? 0))
      return (
        <Stack direction='row' alignItems='center'>
          <SvgIcon
            component={CheckIcon}
            fontSize={textVariant === 'caption' ? 'small' : 'medium'}
            sx={{ mr: '0.3rem', fill: theme.palette.primary.main }}
          />
          <Typography variant={textVariant} sx={{ whiteSpace: 'nowrap' }}>
            Abgespielt
          </Typography>
        </Stack>
      );
    return (
      <Typography variant={textVariant} sx={{ whiteSpace: 'nowrap' }}>
        {hasPlayed
          ? `${formatPlaybackTime(
              lengthSeconds - hasPlayed?.playedSeconds ?? 0,
              shortFormat
            )} verbleibend`
          : formatPlaybackTime(lengthSeconds, shortFormat)}
      </Typography>
    );
  };

  return (
    <Fragment>
      {renderPlaybackTime()}
      {hasPlayed && !isEpisodeFinished(lengthSeconds, hasPlayed?.playedSeconds ?? 0) && (
        <Slider
          value={hasPlayed.played * 100}
          sx={{ ml: '1rem', width: '8rem', pointerEvents: 'none' }}
          min={0}
          max={100}
        />
      )}
    </Fragment>
  );
};
