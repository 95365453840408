import { useQuery } from 'react-query';

import { queryClient, queryKeys } from 'lib/react-query';
import { getPodcastApp } from 'proxy';
import { toFAQ } from 'services';

export const getFAQ = () => getPodcastApp().getContentfulFAQsUsingGET();

export const useFAQ = () => {
  return useQuery(queryKeys.faq.all, getFAQ, {
    initialData: queryClient.getQueryData(queryKeys.faq.all),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.faq.all)?.dataUpdatedAt,
    select: (data) => data.data?.map(toFAQ),
  });
};
