import { Menu, MenuList, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import { theme } from 'styles/theme';

export type LibraryMenuProps = {
  title: string;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
};

export const LibraryMenu = ({
  title,
  anchorEl,
  handleClose,
  children,
}: PropsWithChildren<LibraryMenuProps>) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      id='account-menu'
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: theme.variables.radius.base,
          padding: 2,
          filter: 'drop-shadow(0px 0px 5px rgba(0,0,0,0.1))',
          minWidth: '15rem',
          mt: 1.5,
          '& .MuiList-root': {
            py: 0,
          },
        },
      }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <Typography variant='h6' sx={{ mb: '0.5rem' }}>
        {title}
      </Typography>
      <MenuList>{children}</MenuList>
    </Menu>
  );
};
