import { Box, styled, Typography } from '@mui/material';
import { format } from 'date-fns';

import { ReactComponent as CalendarIcon } from 'assets/icon/calendar.svg';
import { ReactComponent as GlobeIcon } from 'assets/icon/globe.svg';
import { ReactComponent as UserIcon } from 'assets/icon/user.svg';
import { theme } from 'styles/theme';
import { DATE_FORMAT_DISPLAY } from 'utils';

export const StyledCardInfoWrapper = styled(Box)`
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
  svg {
    margin-right: 0.3rem;
  }
`;

type EpisodeInfoProps = {
  createdAt: Date;
  author: string;
  language: string;
};

export const EpisodeInfo = ({ createdAt, author, language }: EpisodeInfoProps) => {
  return (
    <Box>
      <StyledCardInfoWrapper>
        <CalendarIcon fill={theme.palette.grey[500]} />
        <Typography variant='h5'>{format(createdAt, DATE_FORMAT_DISPLAY)}</Typography>
      </StyledCardInfoWrapper>

      <StyledCardInfoWrapper>
        <UserIcon fill={theme.palette.grey[500]} />
        <Typography variant='h5'>{author}</Typography>
      </StyledCardInfoWrapper>

      <StyledCardInfoWrapper>
        <GlobeIcon fill={theme.palette.grey[500]} />
        <Typography variant='h5'>{language}</Typography>
      </StyledCardInfoWrapper>
    </Box>
  );
};
