export const groupBy = <T>(arr: T[], keyFn: (elem: T) => string): { [key: string]: T[] } => {
  return arr.reduce((acc: { [key: string]: T[] }, curr: T) => {
    const key = keyFn(curr);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(curr);
    return acc;
  }, {});
};
