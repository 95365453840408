import { Navigate } from 'react-router-dom';

import { MainLayout, StaticLayout } from 'components/layout';
import { Bookmarked, Category, Discover, Help, Imprint, Landing, Podcast, Search } from 'pages';
import { Episode } from 'pages/Episode';

import { environment } from '../environments';

import { ROUTE_CONSTANTS } from './routeConstants';

export const protectedRoutes = [
  {
    path: ROUTE_CONSTANTS.ROOT,
    element: environment.msal.active ? (
      <Navigate to={ROUTE_CONSTANTS.DISCOVER} replace />
    ) : (
      StaticLayout(<Landing />)
    ),
  },
  {
    path: ROUTE_CONSTANTS.DISCOVER,
    element: MainLayout(<Discover />),
  },
  {
    path: ROUTE_CONSTANTS.PODCAST_DETAIL,
    element: MainLayout(<Podcast />),
  },
  {
    path: ROUTE_CONSTANTS.EPISODE_DETAIL,
    element: MainLayout(<Episode />),
  },
  {
    path: ROUTE_CONSTANTS.CATEGORY_DETAIL,
    element: MainLayout(<Category />),
  },
  {
    path: ROUTE_CONSTANTS.BOOKMARK,
    element: MainLayout(<Bookmarked />),
  },
  {
    path: ROUTE_CONSTANTS.SEARCH,
    element: MainLayout(<Search />),
  },
  {
    path: ROUTE_CONSTANTS.IMPRINT,
    element: MainLayout(<Imprint />),
  },
  {
    path: ROUTE_CONSTANTS.HELP_PAGE,
    element: MainLayout(<Help />),
  },
  // {
  //   path: '*',
  //   element: <Navigate to={ROUTE_CONSTANTS.DISCOVER} />,
  // },
];
