import { Grid, Typography } from '@mui/material';

import { useAllEpisodes } from 'api';
import { PodcastEpisodes } from 'components/podcast';
import { useEpsiodeBookmark } from 'hooks';
import { StyledContainer } from 'styles/components';

export const BookmarkEpisodes = () => {
  const { bookmarks } = useEpsiodeBookmark();
  const { data: episodes = [], isLoading } = useAllEpisodes();

  return (
    <StyledContainer>
      <Grid container spacing={4}>
        <Grid item md={8} width='100%'>
          {bookmarks.size ? (
            <PodcastEpisodes
              episodes={episodes?.filter((episode) => bookmarks.has(episode.id))}
              isLoading={isLoading}
            />
          ) : (
            <Typography variant='h3'>Keine Episoden gemerkt</Typography>
          )}
        </Grid>
        <Grid item md={4} width='100%'></Grid>
      </Grid>
    </StyledContainer>
  );
};
