import { styled } from '@mui/material';
import { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';

import { theme } from 'styles/theme';

import { excludeProps } from '../../utils';

type StyledTitleLink = {
  color?: CSSProperties['color'];
};

export const StyledTitleLink = styled(NavLink, {
  shouldForwardProp: (prop) => excludeProps<StyledTitleLink>(['color'], prop),
})<StyledTitleLink>(({ color = 'black' }) => ({
  textDecoration: 'none',
  width: 'fit-content',
  display: 'block',
  color: color,
  ':hover': {
    color: theme.palette.primary.main,
  },
}));
