import { useQuery } from 'react-query';

import { queryClient, queryKeys } from 'lib/react-query';
import { getPodcastApp } from 'proxy';
import { toLatestEpisode } from 'services';

export const getLatestEpisodes = () => getPodcastApp().getContentfulLatestEpisodesUsingGET();

export const useLatestEpisodes = () => {
  return useQuery(queryKeys.episode.latest(), getLatestEpisodes, {
    initialData: queryClient.getQueryData(queryKeys.episode.latest()),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.episode.latest())?.dataUpdatedAt,
    select: (data) => data.data?.map(toLatestEpisode),
  });
};
