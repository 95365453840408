export const ROUTE_CONSTANTS = {
  ROOT: '/',
  DISCOVER: '/discover',
  PODCAST: '/podcast',
  PODCAST_DETAIL: '/podcast/:id',
  EPISODE: '/episode',
  EPISODE_DETAIL: '/episode/:id',
  CATEGORY: '/category',
  CATEGORY_DETAIL: '/category/:id',
  LIBRARY: '/library',
  BOOKMARK: '/bookmark',
  SEARCH: '/search',
  LEGALS: '/legals',
  IMPRINT: '/imprint',
  TERMS_OF_USE: '/terms-of-use',
  HELP_PAGE: '/help-page',
} as const;
