import { Box, Typography } from '@mui/material';

import { StyledChip } from 'styles/components';
import { theme } from 'styles/theme';

type EpisodeChipsProps = {
  episodeNr: number;
  isLatest: boolean;
};

export const EpisodeChips = ({ episodeNr, isLatest }: EpisodeChipsProps) => {
  return (
    <Box>
      <StyledChip
        label={<Typography variant='body2'>Episode {episodeNr}</Typography>}
        sx={{ mr: '0.5rem', backgroundColor: theme.palette.background.default, color: 'black' }}
      />
      {isLatest && (
        <StyledChip
          label={<Typography variant='body2'>Neuste</Typography>}
          sx={{ backgroundColor: theme.palette.primary.main, color: 'white' }}
        />
      )}
    </Box>
  );
};
