import { useRecoilValue } from 'recoil';

import { modalState } from 'store/modal';

import { AppBanner } from './AppBanner';
import { BasicModal } from './BasicModal';
import { OnboardModal } from './OnboardModal';

export const MODAL_TYPES = {
  BasicModal: 'BasicModal',
  OnboardModal: 'OnboardModal',
  AppBanner: 'AppBanner',
} as const;

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.BasicModal]: BasicModal,
  [MODAL_TYPES.OnboardModal]: OnboardModal,
  [MODAL_TYPES.AppBanner]: AppBanner,
};

export const GlobalModal = () => {
  const { modalType, modalProps } = useRecoilValue(modalState) ?? {};

  const renderComponent = () => {
    if (!modalType) {
      return null;
    }
    const ModalComponent = MODAL_COMPONENTS[modalType];

    return <ModalComponent {...modalProps} />;
  };

  return <>{renderComponent()}</>;
};
