import { CSSProperties } from 'react';

export type AspectRatioVariant = 'wide' | 'square';

export type AspectRatioMap = {
  [Property in AspectRatioVariant]: string;
};

const aspectRatios: AspectRatioMap = {
  wide: '1.5', // 3 / 2
  square: '1',
} as const;

export const getAspectRatioStyle = (
  width: CSSProperties['width'],
  variant: AspectRatioVariant = 'wide'
): CSSProperties => {
  return {
    width: width,
    aspectRatio: aspectRatios[variant],
    objectPosition: 'center',
    ...({
      [`@supports not (aspectRatio: ${aspectRatios[variant]})`]: {
        '&:before': {
          float: 'left',
          paddingTop: `calc(${width} * (1 / ${aspectRatios[variant]}))`,
          content: "''",
        },
        '&:after': {
          display: 'block',
          content: "''",
          clear: 'both',
        },
      },
    } as CSSProperties),
  };
};
