import { Typography } from '@mui/material';

import { HeadingContainer } from 'components/layout';

export const FAQHeader = () => {
  return (
    <HeadingContainer>
      <Typography variant='h2' fontSize={40}>
        FAQ
      </Typography>
    </HeadingContainer>
  );
};
