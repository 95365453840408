import { CloseRounded } from '@mui/icons-material';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { useUpdateEffect } from 'ahooks';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { ReactComponent as SearchIcon } from 'assets/icon/search.svg';
import { useSafeContext } from 'hooks';
import { FilterContext } from 'pages';
import { theme } from 'styles/theme';

type SearchbarProps = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

export const Searchbar = ({ search, setSearch }: SearchbarProps) => {
  const { setFilter } = useSafeContext(FilterContext);
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onReset = () => {
    setFilter({ type: 'all', authors: [] });
    setSearch('');
  };

  useUpdateEffect(() => {
    if (search === '') {
      setFilter({ type: 'all', authors: [] });
    }
  }, [search]);

  return (
    <Stack mt={4} mb={4} spacing={4}>
      <TextField
        fullWidth
        sx={{ backgroundColor: theme.palette.common.white }}
        placeholder='Podcast oder Episode suchen'
        value={search}
        onChange={onSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon style={{ transform: 'scaleX(-1)' }} />
            </InputAdornment>
          ),
          endAdornment: search ? (
            <InputAdornment position='end' onClick={onReset}>
              <CloseRounded />
            </InputAdornment>
          ) : null,
        }}
      >
        Search
      </TextField>
    </Stack>
  );
};
