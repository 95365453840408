import { Container, Skeleton } from '@mui/material';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { useCategories, usePodcasts } from 'api';
import { StyledContainer } from 'styles/components';

import { CategoryHeader } from './CategoryHeader';
import { CategoryLoading } from './CategoryLoading';
import { CategoryPodcasts } from './CategoryPodcasts';

export const CategoryDetail = () => {
  const { id } = useParams();
  // TODO: Would be better to have a single endpoint which returns category info + related podcasts info
  const { data: podcasts = [], isLoading } = usePodcasts(id ?? '');
  const { data: categories, isLoading: isLoadingCategories } = useCategories();

  if (isLoading || isLoadingCategories)
    return (
      <Fragment>
        <CategoryHeader
          heading={<Skeleton sx={{ width: '7rem' }} />}
          description={<Skeleton sx={{ width: '5rem' }} />}
        />
        <Container sx={{ my: '3rem' }}>
          <CategoryLoading />
        </Container>
      </Fragment>
    );

  return (
    <Fragment>
      <CategoryHeader
        heading={podcasts?.[0].categoryName ?? ''}
        description={categories?.find((category) => category.id === id)?.description}
      />
      <StyledContainer>
        <CategoryPodcasts podcasts={podcasts} />
      </StyledContainer>
    </Fragment>
  );
};
