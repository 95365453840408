import { Container, Grid, Skeleton, Typography } from '@mui/material';

import { SkeletonTextSection } from 'components/common';
import { CategoryInfo } from 'components/podcast';
import { Episode } from 'models';
import { StyledPre } from 'styles/components';
import { theme } from 'styles/theme';

import { EpisodeInfo } from '../EpisodeInfo';

type EpisodeDetailsContentProps = {
  episode: Episode;
};

export const EpisodeDetailsContent = ({ episode }: EpisodeDetailsContentProps) => {
  return (
    <Container sx={{ mt: theme.variables.container.mt, mb: '3rem' }}>
      {episode ? (
        <EpisodeInfo
          createdAt={episode.createdAt}
          author={episode.author}
          language={episode.language}
        />
      ) : (
        <Skeleton width='10rem' height='2rem' />
      )}
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant='h4' sx={{ mt: '1rem' }}>
            Beschreibung
          </Typography>
          {episode ? (
            <StyledPre sx={{ mt: '0.5rem', mb: '1rem' }}>{episode.description}</StyledPre>
          ) : (
            <SkeletonTextSection />
          )}
          <CategoryInfo name={episode?.categoryName} />
        </Grid>
      </Grid>
    </Container>
  );
};
