export const storagePrefix = 'pag_podcast_';
export const LOCAL_STORAGE_CONSTANTS = {
  ONBOARDING: 'showOnboarding',
  EPISODE_TIMESTAMP: 'episodeTimestamp',
  EPISODE_BOOKMARK: 'episodeBookmark',
};

export const storage = {
  removeAllTokens: () => {
    window.localStorage.removeItem(`${storagePrefix}${LOCAL_STORAGE_CONSTANTS.ONBOARDING}`);
  },
};
