import { CSSProperties, useState } from 'react';

import { StyledImageSkeleton } from 'styles/components';
import { AspectRatioVariant, getAspectRatioStyle } from 'utils';

type ImageProps = {
  src: string;
  alt: string;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  aspectVariant?: AspectRatioVariant;
  borderRadius?: CSSProperties['borderRadius'];
  renderLoading?: JSX.Element;
  renderError?: JSX.Element;
  isLoading?: boolean;
  ctfWidth?: number;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const Image = ({
  src,
  alt,
  width = '100%',
  height,
  aspectVariant = 'wide',
  borderRadius,
  renderLoading: Loader = <StyledImageSkeleton width={width} aspectVariant={aspectVariant} />,
  renderError: Error = <span>Error loading image</span>,
  isLoading: isLoadingComponent = false,
  ctfWidth,
  ...rest
}: ImageProps) => {
  const { style, ...additionalProps } = rest;
  const [isLoadingImg, setIsLoadingImg] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const onError = () => setIsError(true);
  const onLoad = () => setIsLoadingImg(false);

  const isLoading = isLoadingComponent && isLoadingImg;

  const getSrc = () => {
    if (!src.includes('ctfassets') || !ctfWidth) return src;
    return `${src}?w=${ctfWidth}`;
  };

  if (isError) return Error;

  return (
    <>
      {isLoading && Loader}
      {!isLoadingComponent && (
        <img
          src={getSrc()}
          onError={onError}
          onLoad={onLoad}
          alt={alt}
          loading='lazy'
          className={isLoading ? '' : 'fade-in-image'}
          style={{
            width: width,
            objectFit: 'cover',
            display: isLoading ? 'none' : 'block',
            ...(height && { height: height }),
            ...(borderRadius && { borderRadius: borderRadius }),
            ...getAspectRatioStyle(width, aspectVariant),
            ...style,
          }}
          {...additionalProps}
        />
      )}
    </>
  );
};
