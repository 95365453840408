import { Category, CategoryEpisodes, CategoryPodcasts } from 'models';
import {
  CategoryEpisodesSuggestionResponse,
  CategoryPodcastsSuggestionResponse,
  CategoryResponse,
} from 'proxy';

import { toEpisode } from './episode.service';
import { toPodcast } from './podcast.service';

export const toCategory = (data: CategoryResponse): Category => ({
  id: data.id ?? '',
  name: data.name ?? '',
  description: data.description ?? '',
  image: data.image ?? '',
  podcasts: data.podcasts,
});

export const toCategoryEpisodes = (data: CategoryEpisodesSuggestionResponse): CategoryEpisodes => ({
  id: data.id ?? '',
  name: data.name ?? '',
  description: data.description ?? '',
  image: data.image ?? '',
  episodes: data.podcastEpisodes?.map(toEpisode) ?? [],
});

export const toCategoryPodcasts = (data: CategoryPodcastsSuggestionResponse): CategoryPodcasts => ({
  id: data.id ?? '',
  name: data.name ?? '',
  description: data.description ?? '',
  image: data.image ?? '',
  podcasts: data.podcasts?.map(toPodcast) ?? [],
});
