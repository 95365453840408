import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Fragment } from 'react';

import { PodcastCard } from 'components/podcast';
import { HorizontalScroll, HorizontalScrollTitle } from 'components/scroll';
import { CategoryPodcasts } from 'models';
import { ROUTE_CONSTANTS } from 'routes';
import { theme } from 'styles/theme';

type DiscoverCategoryPodcastsProps = {
  categoryPodcasts: CategoryPodcasts;
};

const StyledPodcastCardWrapper = styled(Box)`
  width: ${theme.variables.podcast.card.width};
  margin-right: ${theme.variables.podcast.card.mr};
`;

export const DiscoverCategoryPodcasts = ({ categoryPodcasts }: DiscoverCategoryPodcastsProps) => {
  return (
    <Fragment>
      <HorizontalScrollTitle
        iconUrl={categoryPodcasts.image}
        title={categoryPodcasts.name}
        description={categoryPodcasts.description}
        link={`${ROUTE_CONSTANTS.CATEGORY}/${categoryPodcasts.id}`}
      />
      <HorizontalScroll>
        {categoryPodcasts.podcasts.map((podcast) => (
          <StyledPodcastCardWrapper key={podcast.id}>
            <PodcastCard podcast={podcast} />
          </StyledPodcastCardWrapper>
        ))}
      </HorizontalScroll>
    </Fragment>
  );
};
