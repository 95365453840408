import { Box, Card, CardContent, Link, Stack, styled, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as GlobeIcon } from 'assets/icon/globe.svg';
import { Image } from 'components/common';
import { StyledCardInfoWrapper } from 'components/episode';
import { Podcast } from 'models';
import { ROUTE_CONSTANTS } from 'routes';
import { theme } from 'styles/theme';
import { remToPx } from 'utils';

const StyledCard = styled(Card)(
  ({ theme }) => `  
  margin-top: 5px;
  transition: transform 0.2s ease-in-out;
  border-radius: ${theme.variables.radius.base};  
  background-color: white;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.1);
  min-height: 10rem;
  :hover {
    transform: translate(0, -5px);
    cursor: pointer;
  }
`
);

type PodcastCardProps = {
  podcast: Podcast;
};

export const PodcastCard = ({ podcast }: PodcastCardProps) => {
  return (
    <Link
      underline='none'
      component={RouterLink}
      to={`${ROUTE_CONSTANTS.PODCAST}/${podcast.id}`}
      style={{ overflow: 'hidden' }}
    >
      <StyledCard>
        <Image
          src={podcast.image}
          alt='podcast thumbnail'
          aspectVariant='square'
          ctfWidth={remToPx(theme.variables.podcast.card.width as string)}
        />
        <CardContent>
          <Typography gutterBottom variant='h4' component='div' fontSize={18}>
            {podcast.title}
          </Typography>
          <Stack mb={1} direction='row' alignItems='center' justifyContent='flex-start' spacing={1}>
            <Typography color={theme.palette.primary.main} fontSize={14} fontWeight={400}>
              {podcast.numberOfEpisodes} Episoden
            </Typography>
            <Box component={'span'} fontSize={10}>
              &bull;
            </Box>
            <StyledCardInfoWrapper>
              <GlobeIcon width={20} height={20} fill={theme.palette.grey[500]} />
              <Typography fontSize={14} variant='h5'>
                {podcast.language}
              </Typography>
            </StyledCardInfoWrapper>
          </Stack>
          <Typography
            fontSize={14}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {podcast.description}
          </Typography>
        </CardContent>
      </StyledCard>
    </Link>
  );
};
