import { Toaster } from 'react-hot-toast';

import { GlobalModal } from 'components/modal';
import { PlayerBar } from 'components/player';
import { ScrollToTop } from 'components/scroll';
import { AppProvider } from 'providers';
import { AppRoutes } from 'routes';

const App = () => {
  return (
    <AppProvider>
      <ScrollToTop />
      <Toaster position='top-center' reverseOrder={false} />
      <GlobalModal />
      <AppRoutes />
      <PlayerBar />
    </AppProvider>
  );
};

export default App;
