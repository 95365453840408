/**
 * Returns an array of unique items from the input array of strings.
 * @param items - The input array of strings.
 * @returns An array of unique strings.
 */
export const getUniqueItems = (items: string[]): string[] => {
  const itemSet = new Set<string>();
  items.forEach((item) => {
    itemSet.add(item);
  });
  return Array.from(itemSet);
};
