import { Box, styled, Typography } from '@mui/material';

import { Image } from 'components/common';
import { useEpisodeTimestamp } from 'hooks';
import { Episode, LatestEpisode } from 'models';
import { ROUTE_CONSTANTS } from 'routes';
import { StyledTitleLink } from 'styles/components';
import { theme } from 'styles/theme';
import { remToPx } from 'utils';

import { EpisodeCardActions } from './EpisodeCardActions';
import { EpisodeDurationInfo } from './EpisodeDurationInfo';

const StyledCardWrapper = styled(Box)`
  width: ${theme.variables.episode.card.width};
  transition: transform 0.2s ease-in-out;
  padding-top: 5px;
  :hover {
    transform: translate(0, -5px);
  }
`;

const StyledCard = styled(Box)(
  ({ theme }) => `  
    position: relative;
    transition: all 0.3s ease-in-out;    
    border-radius: ${theme.variables.radius.base};
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;    
    overflow: hidden;
    :before {
        content: "";
        transition: all 0.3s ease-in-out;
        position: absolute;
        border-radius: ${theme.variables.radius.base};
        opacity: 0;
        left: 0;
        right: 0;
        z-index: 9;
        display: block;
        background-color: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
    }
    :hover:before {                
        border: 3px solid ${theme.palette.primary.main};
        backdrop-filter: blur(5px);
        opacity: 1;               
    }
    :hover .cardActions {
        display: flex;
    }    
`
);

type StyledDurationWrapperProps = {
  isFullWidth: boolean;
};

const StyledDurationWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== 'isFullWidth',
})<StyledDurationWrapperProps>(
  ({ isFullWidth }) => `
  position: absolute;
  left: 0.7rem;
  bottom: 0.6rem;
  background-color: ${theme.palette.secondary.main};
  color: white;
  padding: 0 0.4rem;
  height: 1.4rem;
  font-size: 0.1rem;
  border-radius: ${theme.variables.radius.inner};
  display: flex;
  align-items: center;  
      justify-content: space-between;
  ${isFullWidth && 'width: calc(100% - 1.4rem);'}
`
);

type EpisodeCardProps = {
  episode: LatestEpisode | Episode;
};

export const EpisodeCard = ({ episode }: EpisodeCardProps) => {
  const { episodeTimestamp } = useEpisodeTimestamp(episode.id);

  return (
    <StyledCardWrapper
      sx={{ mr: theme.variables.episode.card.mr }}
      data-test='episode-card-wrapper'
    >
      <StyledCard data-test='episode-card'>
        <EpisodeCardActions episode={episode} podcastId={episode.podcastId} />
        <Image
          src={episode.image}
          alt='Podcast Thumbnail'
          height='100%'
          borderRadius={theme.variables.radius.base}
          ctfWidth={remToPx(theme.variables.episode.card.width as string)}
        />
        <StyledDurationWrapper isFullWidth={!!episodeTimestamp}>
          <EpisodeDurationInfo
            lengthSeconds={episode.lengthSeconds}
            hasPlayed={episodeTimestamp}
            textVariant='caption'
            shortFormat
          />
        </StyledDurationWrapper>
      </StyledCard>
      <StyledTitleLink to={`${ROUTE_CONSTANTS.EPISODE}/${episode.id}`}>
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          {episode.title}
        </Typography>
      </StyledTitleLink>
      <Typography variant='body1'>{episode.podcastName}</Typography>
    </StyledCardWrapper>
  );
};
