import styled from '@emotion/styled';
import { MenuItem, ListItemText, Skeleton, Typography, ListItemIcon, Divider } from '@mui/material';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { useCategories } from 'api';
import { ROUTE_CONSTANTS } from 'routes';
import { StyledIcon } from 'styles/components';
import { theme } from 'styles/theme';
import { excludeProps } from 'utils';

type StyledMenuItemProps = {
  isActive: boolean;
};

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => excludeProps<StyledMenuItemProps>(['isActive'], prop),
})<StyledMenuItemProps>(
  ({ isActive }) => `
  color: ${isActive ? theme.palette.primary.main : 'black'};
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
  ${StyledIcon} {
      background-color: ${isActive ? theme.palette.primary.main : 'black'};
  }
  :hover {
    background-color: white;
    color: ${theme.palette.primary.main};
    ${StyledIcon} {
      background-color: ${theme.palette.primary.main};
    }
  }
`
);

const StyledListItemText = styled(ListItemText)`
  margin-left: 1rem;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
`;

export const HeaderMenuCategories = () => {
  const { data: categories, isLoading } = useCategories();

  if (isLoading)
    return (
      <Fragment>
        {[...Array(5)].map((e, i) => (
          <StyledMenuItem key={i} isActive={false}>
            <Skeleton variant='rounded' width='2.5rem' height='2.5rem' />
            <Skeleton height='2rem' width='20rem' sx={{ ml: '1rem' }} />
          </StyledMenuItem>
        ))}
      </Fragment>
    );

  if (!categories)
    return (
      <Typography variant='h6' sx={{ mb: '0.5rem' }}>
        Keine Kategorien gefunden
      </Typography>
    );

  return (
    <Fragment>
      {categories.map((category, index) => (
        <NavLink
          key={category.id}
          to={`${ROUTE_CONSTANTS.CATEGORY}/${category.id}`}
          style={{ textDecoration: 'none' }}
          state={{ name: category.name }}
        >
          {({ isActive }) => (
            <Fragment>
              <StyledMenuItem isActive={isActive} disableRipple>
                <ListItemIcon>
                  <StyledIcon iconUrl={category.image ?? ''} />
                </ListItemIcon>
                <StyledListItemText disableTypography>
                  <Typography variant='h4'>{category.name}</Typography>
                </StyledListItemText>
              </StyledMenuItem>
              {index !== categories.length - 1 && <Divider />}
            </Fragment>
          )}
        </NavLink>
      ))}
    </Fragment>
  );
};
