import { Box, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { Image } from 'components/common';
import { Episode } from 'models';
import { StyledTitleLink } from 'styles/components';
import { theme } from 'styles/theme';

import { ROUTE_CONSTANTS } from '../../routes';

type PlayerEpisodeInfoProps = {
  episode?: Episode;
  isLoading?: boolean;
};

export const PlayerEpisodeInfo = ({ episode, isLoading = false }: PlayerEpisodeInfoProps) => {
  const isMediaSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack direction='row' flexWrap='wrap' gap={2}>
      {!isMediaSm && (
        <Stack justifyContent='center'>
          <NavLink to={`${ROUTE_CONSTANTS.EPISODE}/${episode?.id}`}>
            <Box
              sx={{
                width: '6rem',
                height: '4rem',
                borderRadius: theme.variables.radius.icon,
                overflow: 'hidden',
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant='rectangular'
                  sx={{ width: '100%', height: '100%', bgcolor: 'grey.600' }}
                />
              ) : (
                <Image src={episode?.image ?? ''} alt='Episode Thumbnail' />
              )}
            </Box>
          </NavLink>
        </Stack>
      )}

      <Stack
        justifyContent='center'
        sx={{
          maxWidth: '100%',
        }}
      >
        <StyledTitleLink to={`${ROUTE_CONSTANTS.EPISODE}/${episode?.id}`} color='white'>
          <Typography
            variant='body1'
            sx={{
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {isLoading ? (
              <Skeleton variant='text' sx={{ bgcolor: 'grey.600', width: '7rem' }} />
            ) : (
              episode?.title
            )}
          </Typography>
        </StyledTitleLink>
        <Typography
          variant='body2'
          sx={{ fontWeight: '100', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {isLoading ? (
            <Skeleton variant='text' sx={{ bgcolor: 'grey.600', width: '5rem' }} />
          ) : (
            `${episode?.podcastName} · ${episode?.author}`
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};
