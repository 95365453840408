import { Box, Typography, Skeleton } from '@mui/material';

type SkeletonTextSectionProps = {
  textLines?: number;
};

export const SkeletonTextSection = ({ textLines = 3 }: SkeletonTextSectionProps) => {
  return (
    <Box sx={{ mt: '0.5rem', mb: '1rem' }}>
      {[...Array(textLines)].map((e, i) => (
        <Typography key={i} variant='body1'>
          <Skeleton animation='wave' />
        </Typography>
      ))}
      <Typography variant='body1'>
        <Skeleton animation='wave' width='80%' />
      </Typography>
    </Box>
  );
};
