import { useQuery } from 'react-query';

import { queryClient, queryKeys } from 'lib/react-query';
import { GetContentfulEpisodesByPodcastUsingGETParams, getPodcastApp } from 'proxy';
import { toEpisode } from 'services';

export const getEpisodes = (params: GetContentfulEpisodesByPodcastUsingGETParams) =>
  getPodcastApp().getContentfulEpisodesByPodcastUsingGET(params);

export const useEpisodes = (podcastId: string) => {
  return useQuery(queryKeys.episode.list(podcastId), () => getEpisodes({ podcast_id: podcastId }), {
    initialData: queryClient.getQueryData(queryKeys.episode.list(podcastId)),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.episode.list(podcastId))
      ?.dataUpdatedAt,
    select: (data) => data.data?.map(toEpisode),
    enabled: !!podcastId,
  });
};
