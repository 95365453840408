import { Box, Container, Grid, styled, Link as ExternalLink } from '@mui/material';
import { Link } from 'react-router-dom';

import { ROUTE_CONSTANTS } from 'routes';
import { theme } from 'styles/theme';

const StyledFooter = styled(Box)(
  ({ theme }) => `    
    width: 100%;
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.text.secondary};    
`
);

const StyledNavLink = styled(Link)(
  ({ theme }) => `
    color: ${theme.palette.text.secondary};
    font-weight: 100;
`
);

export const Footer = () => {
  return (
    <StyledFooter component='footer'>
      <Container sx={{ height: '100%' }}>
        <Grid
          container
          justifyContent={{ xs: 'flex-start', md: 'space-between' }}
          alignItems='center'
          height='100%'
          sx={{ py: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ fontWeight: 'bold' }}>
            ©2022 Dr. Ing. h.c. F. Porsche AG
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            gap={2}
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            alignItems={'flex-start'}
            marginTop={{ xs: 2, md: 0 }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <ExternalLink
              href={`${window.location.origin}/porsche_legals.pdf`}
              target='_blank'
              color='text.secondary'
              sx={{ textDecorationColor: theme.palette.text.secondary }}
            >
              Datenschutz
            </ExternalLink>
            <StyledNavLink to={ROUTE_CONSTANTS.IMPRINT}>Impressum</StyledNavLink>
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  );
};
