import { Podcast } from 'models';
import { PodcastResponse } from 'proxy';

export const toPodcast = (data: PodcastResponse): Podcast => ({
  id: data.id ?? '',
  title: data.title ?? '',
  description: data.description ?? '',
  categoryId: data.categoryId ?? '',
  categoryName: data.categoryName ?? '',
  episodes: data.episodes ?? [],
  image: data.image ?? '',
  language: data.language ?? '',
  numberOfEpisodes: data.numberOfEpisodes ?? 0,
});
