import { Grid } from '@mui/material';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { useEpisodes, usePodcast } from 'api';
import { StyledContainer } from 'styles/components';

import { PodcastEpisodes } from './PodcastEpisodes';
import { PodcastHeader } from './PodcastHeader';
import { PodcastInfo } from './PodcastInfo';

export const PodcastDetail = () => {
  const { id } = useParams();
  const { data: podcast, isLoading: isLoadingPodcast } = usePodcast(id ?? '');
  const { data: episodes, isLoading: isLoadingEpisodes } = useEpisodes(podcast?.id ?? '');

  return (
    <Fragment>
      <PodcastHeader
        heading={podcast?.title ?? ''}
        categoryId={podcast?.categoryId ?? ''}
        categoryName={podcast?.categoryName ?? ''}
        isLoading={isLoadingPodcast}
      />
      <StyledContainer>
        <Grid container spacing={4}>
          <Grid item md={8} width='100%'>
            <PodcastEpisodes episodes={episodes} isLoading={isLoadingEpisodes} />
          </Grid>
          <Grid item md={4} width='100%'>
            <PodcastInfo
              podcast={podcast}
              categoryName={podcast?.categoryName ?? ''}
              isLoading={isLoadingPodcast}
            />
          </Grid>
        </Grid>
      </StyledContainer>
    </Fragment>
  );
};
