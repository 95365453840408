import { Box, Stack, SvgIcon, Tooltip } from '@mui/material';

import { ReactComponent as BookmarkIconFilled } from 'assets/icon/bookmark-filled.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icon/bookmark.svg';
import { ReactComponent as UploadIcon } from 'assets/icon/upload.svg';
import { useCopyToClipboard, useEpsiodeBookmark } from 'hooks';
import { Episode } from 'models';
import { theme } from 'styles/theme';

type EpisodeActionsProps = {
  episodeId: Episode['id'];
};

export const EpisodeActions = ({ episodeId }: EpisodeActionsProps) => {
  const { isBookmarked, toggleEpisodeBookmark } = useEpsiodeBookmark();
  const { isCopied, handleCopyClick } = useCopyToClipboard();

  return (
    <Stack spacing={2} direction='row' alignItems='center'>
      <Tooltip title={isBookmarked(episodeId) ? 'Gemerkt' : 'Merken'} placement='top'>
        <Box
          display='flex'
          sx={{ cursor: 'pointer' }}
          onClick={() => toggleEpisodeBookmark(episodeId)}
        >
          <SvgIcon
            component={isBookmarked(episodeId) ? BookmarkIconFilled : BookmarkIcon}
            sx={{
              ':hover': {
                fill: theme.palette.primary.main,
              },
            }}
          />
        </Box>
      </Tooltip>
      <Tooltip title={isCopied ? 'Kopiert!' : 'Link kopieren'} placement='top'>
        <Box
          display='flex'
          sx={{
            cursor: 'pointer',
          }}
          onClick={() =>
            handleCopyClick(`${location.protocol}//${location.host}/episode/${episodeId}`)
          }
        >
          <SvgIcon
            component={UploadIcon}
            sx={{
              ':hover': {
                fill: theme.palette.primary.main,
              },
            }}
          />
        </Box>
      </Tooltip>
    </Stack>
  );
};
