import { Box, Grid, Stack, Typography } from '@mui/material';

import { Image, NavigateBackButton, PlayButton } from 'components/common';
import { HeadingContainer } from 'components/layout';
import { useEpisodeTimestamp, usePlayer } from 'hooks';
import { AnalyticsService } from 'lib/AnalyticsService';
import { Episode } from 'models';
import { ROUTE_CONSTANTS } from 'routes';
import { StyledImageSkeleton } from 'styles/components';
import { theme } from 'styles/theme';

import { EpisodeActions } from '../EpisodeActions';
import { EpisodeChips } from '../EpisodeChips';
import { EpisodeDurationInfo } from '../EpisodeDurationInfo';

type EpisodeDetailsHeaderProps = {
  episode: Episode;
};

export const EpisodeDetailsHeader = ({ episode }: EpisodeDetailsHeaderProps) => {
  const { handleClickPlay } = usePlayer();
  const { episodeTimestamp } = useEpisodeTimestamp(episode?.id ?? '');
  return (
    <HeadingContainer>
      <NavigateBackButton
        title={episode?.podcastName ?? ''}
        link={`${ROUTE_CONSTANTS.PODCAST}/${episode?.podcastId}`}
      />
      <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ mt: 2 }}>
        <Box sx={{ width: { xs: '100%', sm: '25rem' } }}>
          <Image
            src={episode?.image ?? ''}
            alt='Podcast Thumbnail'
            borderRadius={theme.variables.radius.base}
            renderLoading={<StyledImageSkeleton sx={{ bgcolor: theme.palette.grey[500] }} />}
            ctfWidth={500}
          />
        </Box>

        <Grid
          container
          item
          xs={12}
          sm={8}
          sx={{ pl: { sm: '2rem' } }}
          direction='column'
          justifyContent='space-between'
        >
          <Stack>
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ mt: { xs: '1rem', sm: 0 } }}
            >
              <Stack direction='row' sx={{ mt: { xs: '1rem', sm: 0 } }}>
                <EpisodeChips episodeNr={episode?.episodeNumber ?? 0} isLatest={false} />
                <Stack sx={{ ml: '1rem' }} justifyContent='center'>
                  <EpisodeActions episodeId={episode?.id ?? ''} />
                </Stack>
              </Stack>
            </Stack>

            <Typography variant='h1' sx={{ mt: '1rem' }}>
              {episode?.title}
            </Typography>
            <Typography variant='h3' sx={{ mt: '1rem' }}>
              {episode?.podcastName}
            </Typography>
          </Stack>

          <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
            <PlayButton
              episodeId={episode?.id ?? ''}
              onClick={() => {
                !episodeTimestamp && AnalyticsService.trackEpisodeStart(episode);
                handleClickPlay(episode?.id ?? '');
              }}
              color='info'
              sx={{ backgroundColor: 'white', mr: '1rem' }}
            />
            <EpisodeDurationInfo
              lengthSeconds={episode?.lengthSeconds ?? 0}
              hasPlayed={episodeTimestamp}
            />
          </Stack>
        </Grid>
      </Stack>
    </HeadingContainer>
  );
};
