import { SortDirection } from '@mui/material';
import { compareAsc, compareDesc } from 'date-fns';
import { useMemo, useState } from 'react';

type UseSortByDateProps<T extends Record<P, Date>, P extends keyof T> = {
  arr: T[];
  sortProp: P;
  direction?: SortDirection;
};

export const useSortByDate = <T extends Record<P, Date>, P extends keyof T>({
  arr,
  sortProp,
  direction = 'desc',
}: UseSortByDateProps<T, P>) => {
  const [sortDirection, setSortDirection] = useState<SortDirection>(direction);

  const toggleDirection = () => setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));

  const sortedArr = useMemo(
    () =>
      arr.sort((a, b) =>
        sortDirection === 'asc'
          ? compareAsc(a[sortProp], b[sortProp])
          : compareDesc(a[sortProp], b[sortProp])
      ),
    [arr, sortProp, sortDirection]
  );

  return { sortedArr, sortDirection, toggleDirection };
};
