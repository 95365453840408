import { Typography, Chip, Skeleton } from '@mui/material';
import { Fragment, ReactNode } from 'react';

import { theme } from 'styles/theme';

type CategoryInfoProps = {
  name: ReactNode;
};

export const CategoryInfo = ({ name }: CategoryInfoProps) => {
  return (
    <Fragment>
      <Typography variant='subtitle1' sx={{ mt: '0.5rem', fontWeight: '600' }}>
        Kategorie:
      </Typography>
      {name ? (
        <Chip label={name} color='secondary' sx={{ mt: '0.5rem', mb: '1rem' }} />
      ) : (
        <Skeleton
          variant='rounded'
          width='7rem'
          sx={{
            height: '2rem',
            mt: '0.5rem',
            mb: '1rem',
            borderRadius: theme.variables.radius.base,
          }}
        />
      )}
    </Fragment>
  );
};
