import { useDebounce } from 'ahooks';
import { createContext, Dispatch, SetStateAction, useState } from 'react';

import { useSearch } from 'api';
import { Searchbar, SearchResults } from 'components/search';
import { FilterSelection } from 'models';
import { StyledContainer } from 'styles/components';

type FilterContextValue = {
  filter: FilterSelection;
  setFilter: Dispatch<SetStateAction<FilterSelection>>;
};

export const FilterContext = createContext<FilterContextValue | undefined>(undefined);

export const Search = () => {
  const [filter, setFilter] = useState<FilterSelection>({ type: 'all', authors: [] });
  const [search, setSearch] = useState('');
  const debouncedSearchTerm: string = useDebounce<string>(search, { wait: 500 });
  const { data: searchResults = [], isLoading } = useSearch(debouncedSearchTerm);

  return (
    <StyledContainer maxWidth='sm'>
      <FilterContext.Provider
        value={{
          filter,
          setFilter,
        }}
      >
        <Searchbar search={search} setSearch={setSearch} />
        <SearchResults results={searchResults} search={search} isLoading={isLoading} />
      </FilterContext.Provider>
    </StyledContainer>
  );
};
