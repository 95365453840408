import { SelectChangeEvent } from '@mui/material';

import { FilterSelection, FullTextSearch } from 'models';
import { FilterContext } from 'pages';

import { useSafeContext } from './useSafeContext';

export const useSearchFilter = (searchResults: FullTextSearch[]) => {
  const { filter, setFilter } = useSafeContext(FilterContext);

  const handleFilterAuthorChange = (event: SelectChangeEvent<FilterSelection['authors']>) => {
    const {
      target: { value },
    } = event;
    setFilter((prev) => ({
      ...prev,
      authors: typeof value === 'string' ? value.split(',') : value,
    }));
    if (value) setFilter((prev) => ({ ...prev, type: 'episode' }));
  };

  const handleFilterTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilterType: FilterSelection['type']
  ) => {
    if (newFilterType === 'episode') setFilter((prev) => ({ ...prev, type: newFilterType }));
    setFilter({ type: newFilterType, authors: [] });
  };

  const filteredResults = searchResults.filter((result) => {
    if (filter.type === 'episode' && filter.authors.length)
      return result.author && filter.authors.includes(result.author);
    return filter.type === 'all' || result.content_type === filter.type;
  });

  return {
    filter,
    filteredResults,
    handleFilterAuthorChange,
    handleFilterTypeChange,
  };
};
