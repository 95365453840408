import { Fragment } from 'react';

import { FAQ, FAQHeader } from 'components/help';

export const Help = () => {
  return (
    <Fragment>
      <FAQHeader />
      <FAQ />
    </Fragment>
  );
};
