import { useRecoilState } from 'recoil';

import { modalState, ModalType } from 'store/modal';

export function useModal() {
  const [modal, setModal] = useRecoilState(modalState);

  const showModal = (modal: ModalType) => {
    setModal(modal);
  };

  const hideModal = () => {
    setModal(null);
  };

  return {
    modal,
    setModal,
    showModal,
    hideModal,
  };
}
