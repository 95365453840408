import { Avatar, Box, Divider, Drawer, IconButton, Link, SvgIcon, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as BookmarkIcon } from 'assets/icon/bookmark.svg';
import { ReactComponent as BroadcastIcon } from 'assets/icon/broadcast.svg';
import { ReactComponent as GridIcon } from 'assets/icon/grid.svg';
import { ReactComponent as LightIcon } from 'assets/icon/light.svg';
import { ReactComponent as SearchIcon } from 'assets/icon/search.svg';
import { useUser } from 'lib';
import { ROUTE_CONSTANTS } from 'routes';
import { theme } from 'styles/theme';
import { getInitials } from 'utils';

import { HeaderMenuProps } from './HeaderMenu';
import { LogoutButton } from './LogoutButton';
import { NavigationButton, StyledNavItem } from './NavigationButton';

import { HeaderMenuCategories, LibraryMenu } from '.';

export const HeaderMobileMenu = ({ anchorEl, handleClose }: HeaderMenuProps) => {
  const open = Boolean(anchorEl);
  const username = useUser();

  const [anchorElLibrary, setAnchorElLibrary] = useState<null | HTMLElement>(null);

  const handleLibraryMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLibrary(event.currentTarget);
  };
  const handleLibraryMenuClose = () => {
    setAnchorElLibrary(null);
  };

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: '15rem',
        },
      }}
    >
      <Box sx={{ p: 3 }}>
        <IconButton color='secondary' sx={{ p: 0, mb: 1 }}>
          <Avatar sx={{ backgroundColor: theme.palette.grey[800] }}>
            {getInitials(username ?? '')}
          </Avatar>
        </IconButton>
        <Typography variant='body1'>{username}</Typography>
      </Box>
      <Box sx={{ py: 2, px: 1 }}>
        <NavigationButton
          title={'Entdecken'}
          startIcon={BroadcastIcon}
          link={ROUTE_CONSTANTS.DISCOVER}
          sx={{ fontWeight: 'bold' }}
        />
        <StyledNavItem disableRipple onClick={handleLibraryMenuClick} sx={{ fontWeight: 'bold' }}>
          <SvgIcon component={GridIcon} sx={{ mr: 1 }}></SvgIcon>
          Bibliothek
        </StyledNavItem>
        <NavigationButton
          title={'Gemerkt'}
          startIcon={BookmarkIcon}
          link={ROUTE_CONSTANTS.BOOKMARK}
          sx={{ fontWeight: 'bold' }}
        />
        <NavigationButton
          title={'Suchen'}
          startIcon={SearchIcon}
          link={ROUTE_CONSTANTS.SEARCH}
          sx={{ fontWeight: 'bold' }}
        />
      </Box>
      <Divider />
      <Box sx={{ py: 2, px: 1 }}>
        <NavigationButton title='FAQ' startIcon={LightIcon} link={ROUTE_CONSTANTS.HELP_PAGE} />
      </Box>
      <Divider />
      <Box sx={{ p: 3 }} display='flex' flexDirection={'column'} rowGap={2}>
        <Link
          href={`${window.location.origin}/porsche_legals.pdf`}
          sx={{ textDecoration: 'none' }}
          target='_blank'
        >
          <Typography variant='h5'>Datenschutz</Typography>
        </Link>
        <NavLink to={ROUTE_CONSTANTS.IMPRINT} style={{ textDecoration: 'none' }}>
          <Typography variant='h5'>Impressum</Typography>
        </NavLink>
      </Box>
      <Box sx={{ p: 3 }}>
        <LogoutButton />
      </Box>
      <LibraryMenu
        title='Kategorien'
        anchorEl={anchorElLibrary}
        handleClose={handleLibraryMenuClose}
      >
        <HeaderMenuCategories />
      </LibraryMenu>
    </Drawer>
  );
};
