import { Typography } from '@mui/material';

import { HeadingContainer } from 'components/layout';
import { useUser } from 'lib';

export const DiscoverHeader = () => {
  const username = useUser();
  return (
    <HeadingContainer>
      <Typography variant='body1'>
        {username ? 'Hallo ' : 'Willkommen'}
        {username},
      </Typography>
      <Typography variant='h1'>Entdecke Podcasts</Typography>
    </HeadingContainer>
  );
};
