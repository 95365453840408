import { Box, Button, Grid, Link, styled, SvgIcon, Typography } from '@mui/material';

import { ReactComponent as ArrowHeadRightIcon } from 'assets/icon/arrow-head-right.svg';
import PorscheLogo from 'assets/img/logo.png';
import { ReactComponent as PodcastLogo } from 'assets/img/podcast-logo.svg';
import BackgroundImg from 'assets/img/podcast_background.png';
import { environment } from 'environments';
import { ROUTE_CONSTANTS } from 'routes';

import packageJson from '../../package.json';
import useMsal, { loginRequest } from '../lib/authConfig';

const StyledWrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 7rem 2rem 3rem 2rem;
  text-align: center;
`;

const StyledCoverImage = styled('img')`
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const StyledLink = styled(Link)(
  ({ theme }) => `
    color: ${theme.palette.grey[600]};    
    text-decoration-color: ${theme.palette.grey[600]};
    font-weight: 100;
`
);

const { msal } = environment;

export const Landing = () => {
  const { instance, inProgress } = useMsal();

  const handleLogin = () => {
    if (msal.active) {
      instance?.loginRedirect(loginRequest);
    }

    location.href = ROUTE_CONSTANTS.DISCOVER;
  };

  return (
    <Grid container sx={{ height: '100vh', overflow: 'hidden' }}>
      <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledWrapper>
          <Box component='img' alt='Porsche Logo' src={PorscheLogo} />
          <Box>
            <Typography variant='h2'>
              Podcast@Porsche
              <br />
              Hör doch mal rein.
            </Typography>
            <Button
              sx={{ width: '100%', py: 1.3, fontSize: '1.2rem', fontWeight: '600', my: 3 }}
              variant='contained'
              endIcon={<ArrowHeadRightIcon fill='#fff' />}
              onClick={handleLogin}
              disabled={inProgress === 'login'}
            >
              Anmelden
            </Button>
            <Typography variant='h5'>
              Durch fortsetzen bestätige ich die Porsche{' '}
              <StyledLink href={`${window.location.origin}/porsche_legals.pdf`} target='_blank'>
                Datenschutzerklärung
              </StyledLink>
            </Typography>
          </Box>
          <Box>
            <SvgIcon
              component={PodcastLogo}
              inheritViewBox
              sx={{ fontSize: '60px', cursor: 'pointer' }}
            />
            <Typography variant='h3'>Porsche Podcast App</Typography>
            <Typography variant='h5'>Release {packageJson.version}</Typography>
          </Box>
        </StyledWrapper>
      </Grid>
      <Grid item md={8} display={{ xs: 'none', md: 'block' }}>
        <StyledCoverImage alt='Mountain view with porsche taycan' src={BackgroundImg} />
      </Grid>
    </Grid>
  );
};
