import { ErrorResponse } from 'proxy';

export class APIError extends Error {
  public errors: ErrorResponse[];

  constructor(errors: ErrorResponse[]) {
    super('API Error');
    this.name = 'APIError';
    this.errors = errors;
  }
}
