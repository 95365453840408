import { Episode, EpisodeTimestamp, LatestEpisode, Podcast, Progress } from 'models';

// Declare the newrelic global variable
declare global {
  interface Window {
    newrelic: {
      addPageAction: (name: string, properties: Record<string, any>) => void;
      setUserId: (value: string | null) => void;
    };
  }
}

export const AnalyticsService = {
  // Adds a user-defined identifier string to subsequent events on the page.
  setUserId: (userId: string) => {
    console.log('[New Relic]: setUserId', userId);
    window.newrelic?.setUserId(userId);
  },

  // Triggered whenever an episode is first played
  trackEpisodeStart: (episode: Episode | LatestEpisode) => {
    console.log('[New Relic]: episode.play', episode);
    window.newrelic?.addPageAction('episode.play', {
      podcastTitle: episode.podcastName,
      podcastId: episode.podcastId,
      episodeTitle: episode.title,
      episodeId: episode.id,
    });
  },

  // Triggered whenever an episode is ended
  trackEpisodeEnded: (episode: Episode) => {
    console.log('[New Relic]: episode.ended', episode);
    window.newrelic?.addPageAction('episode.ended', {
      podcastTitle: episode.podcastName,
      podcastId: episode.podcastId,
      episodeTitle: episode.title,
      episodeId: episode.id,
    });
  },

  // Triggered whenever an episode detail page is navigated
  trackEpisodeOpen: (episode: Episode) => {
    console.log('[New Relic]: episode.opened', episode);
    window.newrelic?.addPageAction('episode.opened', {
      podcastTitle: episode.podcastName,
      podcastId: episode.podcastId,
      episodeTitle: episode.title,
      episodeId: episode.id,
    });
  },

  // Triggered whenever a podcast detail page is navigated
  trackPodcastOpen: (podcast: Podcast) => {
    console.log('[New Relic]: podcast.opened', podcast);
    window.newrelic?.addPageAction('podcast.opened', {
      podcastTitle: podcast.title,
      podcastId: podcast.id,
    });
  },
};

// Check if at least 85% of the episode was played and less than 5 minutes is left (for longer episodes)
const isWithinEndedThreshold = (
  playedPercentage: number,
  playedSeconds: number,
  episodeLengthSeconds: number
) => playedPercentage > 0.85 && playedSeconds > episodeLengthSeconds - 300;

export const triggerEpisodeEndedFirstTime = (
  episode: Episode,
  progress: Progress,
  episodeTimestamp: EpisodeTimestamp
) => {
  // if no timestamp exist and next timestamp would be in threshold -> trigger episode ended
  if (!episodeTimestamp?.played) {
    if (isWithinEndedThreshold(progress.played, progress.playedSeconds, episode.lengthSeconds)) {
      AnalyticsService.trackEpisodeEnded(episode);
    }
  } else {
    // Old timestamp exists and if new timestamp is in treshold and old timestamp wasn't -> trigger episode ended
    const isOldTimestampInThreshold = isWithinEndedThreshold(
      episodeTimestamp.played,
      episodeTimestamp.playedSeconds,
      episode.lengthSeconds
    );
    const isNewTimestampInThreshold = isWithinEndedThreshold(
      progress.played,
      progress.playedSeconds,
      episode.lengthSeconds
    );
    if (!isOldTimestampInThreshold && isNewTimestampInThreshold) {
      AnalyticsService.trackEpisodeEnded(episode);
    }
  }
};
