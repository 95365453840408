import { useIsAuthenticated } from '@azure/msal-react';
import { Container, styled } from '@mui/material';
import { useEffect, useState } from 'react';

import { Footer } from 'components/footer';
import { Header } from 'components/header';
import { useLocalStorage, useModal } from 'hooks';
import { theme } from 'styles/theme';
import { storagePrefix, LOCAL_STORAGE_CONSTANTS, isIOSDevice } from 'utils';

const BaseLayoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
`;

const StyledContainer = styled(Container)`
  flex: 1;
`;

const StyledMain = styled('main')`
  flex: 1;
`;

export type LayoutProps = {
  children?: React.ReactNode;
  styles?: React.CSSProperties;
  withHeader?: boolean;
  withContainer?: boolean;
  withFooter?: boolean;
};

export const BaseLayout = ({
  children,
  styles,
  withHeader = true,
  withContainer = true,
  withFooter = true,
}: LayoutProps) => {
  const isAuthenticated = useIsAuthenticated();
  const [showOnboarding, setShowOnboarding] = useLocalStorage<'true' | 'false'>(
    `${storagePrefix}${LOCAL_STORAGE_CONSTANTS.ONBOARDING}`,
    'true'
  );
  const [showAppBanner, setShowAppBanner] = useState(isIOSDevice());
  const { showModal } = useModal();

  useEffect(() => {
    if (isAuthenticated && showOnboarding === 'true') {
      showModal({
        modalType: 'OnboardModal',
        modalProps: {
          handleClose: () => {
            setShowOnboarding('false');
          },
        },
      });
    }
    if (isAuthenticated && showAppBanner) {
      showModal({
        modalType: 'AppBanner',
        modalProps: {
          handleClose: () => {
            setShowAppBanner(false);
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayoutContainer>
      {withHeader && <Header />}
      {withContainer ? (
        <StyledContainer
          disableGutters
          style={{ ...styles }}
          sx={{ ...(withHeader && { pt: theme.variables.header.height }) }}
        >
          {children}
        </StyledContainer>
      ) : (
        <StyledMain
          style={{ ...styles }}
          sx={{ ...(withHeader && { pt: theme.variables.header.height }) }}
        >
          {children}
        </StyledMain>
      )}
      {withFooter && <Footer />}
    </BaseLayoutContainer>
  );
};
