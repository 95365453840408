import { Episode, LatestEpisode } from 'models';
import { LatestEpisodesResponse, PodcastEpisodesResponse } from 'proxy';
import { parseDate } from 'utils';

export const toEpisode = (data: PodcastEpisodesResponse): Episode => ({
  id: data.id ?? '',
  title: data.title ?? '',
  audio: data.audio ?? '',
  author: data.author ?? '',
  createdAt: parseDate(data.createdAt) ?? new Date(),
  description: data.description ?? '',
  episodeNumber: data.episodeNumber ?? 0,
  image: data.image ?? '',
  language: data.language ?? '',
  lengthSeconds: data.lengthSeconds ?? 0,
  podcastId: data.podcastId ?? '',
  podcastName: data.podcastName ?? '',
  categoryName: data.categoryName ?? '',
});

export const toLatestEpisode = (data: LatestEpisodesResponse): LatestEpisode => ({
  id: data.id ?? '',
  title: data.name ?? '',
  categoryId: data.category ?? '',
  category: data.category ?? '',
  createdAt: data.createdAt ?? '',
  episodeNumber: data.episodeNumber ?? 0,
  image: data.image ?? '',
  podcastId: data.podcastId ?? '',
  podcastName: data.podcast ?? '',
  lengthSeconds: data.lengthSeconds ?? 0,
});
