import { Button, ButtonProps, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

import { theme } from 'styles/theme';

type FilterButtonProps = {
  is_active: boolean;
} & ButtonProps;

export const FilterButton = styled((props: PropsWithChildren<FilterButtonProps>) => (
  <Button {...props}>{props.children}</Button>
))`
  border-radius: 16px;
  font-weight: 600;
  box-shadow: none;
  height: 32px;
  background-color: ${({ is_active }: { is_active: boolean }) =>
    is_active ? theme.palette.common.white : theme.palette.common.black};
  color: ${({ is_active }: { is_active: boolean }) =>
    is_active ? theme.palette.common.black : theme.palette.common.white};
  &:hover {
    color: ${({ is_active }: { is_active: boolean }) =>
      is_active ? theme.palette.common.white : theme.palette.common.black};
    background-color: ${({ is_active }: { is_active: boolean }) =>
      is_active ? theme.palette.common.black : theme.palette.common.white};
    box-shadow: none;
  }
`;
