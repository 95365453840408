import { Slider, Stack, SvgIcon } from '@mui/material';

import { ReactComponent as VolumeOffIcon } from 'assets/icon/volume-off.svg';
import { ReactComponent as VolumeUpIcon } from 'assets/icon/volume-up.svg';

type VolumeSliderProps = {
  volume: number;
  handleVolumeChange: (event: Event, newValue: number | number[]) => void;
  handleVolumeOn: () => void;
  handleVolumeOff: () => void;
};

export const VolumeSlider = ({
  volume,
  handleVolumeChange,
  handleVolumeOff,
  handleVolumeOn,
}: VolumeSliderProps) => {
  return (
    <Stack spacing={2} direction='row' alignItems='center'>
      {volume === 0 ? (
        <SvgIcon
          component={VolumeOffIcon}
          fill='white'
          onClick={() => handleVolumeOff()}
          sx={{ cursor: 'pointer' }}
        />
      ) : (
        <SvgIcon
          component={VolumeUpIcon}
          fill='white'
          onClick={() => handleVolumeOn()}
          sx={{ cursor: 'pointer' }}
        />
      )}
      <Slider
        color='secondary'
        aria-label='Volume'
        value={volume}
        onChange={handleVolumeChange}
        sx={{ width: '9rem', py: { xs: '10px' } }}
      />
    </Stack>
  );
};
