import { useQuery } from 'react-query';

import { queryClient, queryKeys } from 'lib/react-query';
import { getPodcastApp } from 'proxy';
import { toCategory } from 'services';

export const getCategories = () => getPodcastApp().getContentfulCategoriesUsingGET();

export const useCategories = () => {
  return useQuery(queryKeys.category.lists(), getCategories, {
    initialData: queryClient.getQueryData(queryKeys.category.lists()),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.category.lists())?.dataUpdatedAt,
    select: (data) => data.data?.map(toCategory),
  });
};
