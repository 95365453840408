import { MenuItem, styled, SvgIcon, SxProps, Theme } from '@mui/material';
import { useHover } from 'ahooks';
import { useRef, ElementType } from 'react';
import { NavLink } from 'react-router-dom';

type StyledMenuItemProps = {
  isActive?: boolean;
};

export const StyledNavItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<StyledMenuItemProps>(
  ({ theme, isActive = false }) => `
    color: ${isActive ? theme.palette.primary.main : theme.palette.text.primary};
    fill: ${isActive ? theme.palette.primary.main : theme.palette.text.primary};
    border-radius: ${theme.variables.radius.chip};
    &:hover {
        color: ${theme.palette.primary.main};
        fill: ${theme.palette.primary.main};        
    }`
);

export type NavigationButtonProps = {
  title: string;
  startIcon?: ElementType;
  activeStartIcon?: ElementType;
  endIcon?: ElementType;
  activeEndIcon?: ElementType;
  link: string;
  sx?: SxProps<Theme> | undefined;
  state?: any;
};

export const NavigationButton = ({
  title,
  startIcon,
  activeStartIcon,
  endIcon,
  activeEndIcon,
  link,
  sx,
  state,
}: NavigationButtonProps) => {
  const ref = useRef(null);
  const isHovering = useHover(ref);
  return (
    <NavLink to={link} ref={ref} style={{ textDecoration: 'none' }} state={state}>
      {({ isActive }) => (
        <StyledNavItem
          isActive={isActive}
          sx={{ ...((isActive || isHovering) && { fontWeight: 'bold' }), ...sx }}
          disableRipple
        >
          {startIcon && (
            <SvgIcon
              component={activeStartIcon && (isActive || isHovering) ? activeStartIcon : startIcon}
              sx={{ mr: 1 }}
            />
          )}
          {title}
          {endIcon && (
            <SvgIcon
              component={activeEndIcon && (isActive || isHovering) ? activeEndIcon : endIcon}
              sx={{ ml: 1 }}
            />
          )}
        </StyledNavItem>
      )}
    </NavLink>
  );
};
