import { Fragment } from 'react';

import { BookmarkEpisodes, BookmarkHeader } from 'components/bookmark';

export const Bookmarked = () => {
  return (
    <Fragment>
      <BookmarkHeader />
      <BookmarkEpisodes />
    </Fragment>
  );
};
