import { Typography } from '@mui/material';
import { ReactNode } from 'react';

import { NavigateBackButton } from 'components/common';
import { HeadingContainer } from 'components/layout';

type CategoryHeaderProps = {
  heading: ReactNode;
  description: ReactNode;
};

export const CategoryHeader = ({ heading, description }: CategoryHeaderProps) => {
  return (
    <HeadingContainer>
      <NavigateBackButton title='Kategorien' link={'/discover#categories'} />
      <Typography variant='h1'>{heading}</Typography>
      <Typography>{description}</Typography>
    </HeadingContainer>
  );
};
