export const isIOSDevice = (): boolean => {
  console.log(navigator.userAgent);

  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && 'ontouchend' in document) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
