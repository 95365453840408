import { useState } from 'react';

import { copyTextToClipboard } from 'utils';

export const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopyClick = (text: string) => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return { isCopied, handleCopyClick };
};
