import { Skeleton, Stack, Typography } from '@mui/material';

import { ReactComponent as ArrowHeadRightIcon } from 'assets/icon/arrow-head-right.svg';
import { NavigationButton } from 'components/header';
import { StyledIcon, StyledTitleLink } from 'styles/components';
import { theme } from 'styles/theme';

type HorizontalScrollTitleProps = {
  title: string;
  description?: string;
  link: string;
  iconUrl?: string;
  isLoading?: boolean;
  routeState?: any;
};

export const HorizontalScrollTitle = ({
  title,
  description,
  link,
  iconUrl,
  isLoading = false,
  routeState,
}: HorizontalScrollTitleProps) => {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ sm: 'center' }}
      gap={{ xs: 2 }}
      justifyContent='space-between'
      mt={2}
      mb={1}
    >
      <Stack direction='row' alignItems='center'>
        {iconUrl &&
          (isLoading ? (
            <Skeleton
              variant='rectangular'
              width='3rem'
              height='3rem'
              sx={{ borderRadius: theme.variables.radius.icon }}
            />
          ) : (
            <StyledIcon iconUrl={iconUrl} sx={{ width: '3rem', height: '3rem' }} />
          ))}
        <Stack>
          <StyledTitleLink to={link} state={routeState}>
            <Typography variant='h3' marginLeft={iconUrl ? 2 : 0}>
              {isLoading ? <Skeleton height='2rem' width='8rem' /> : title}
            </Typography>
          </StyledTitleLink>
          {description && (
            <Typography marginLeft={iconUrl ? 2 : 0}>
              {isLoading ? <Skeleton height='1rem' width='10rem' /> : description}
            </Typography>
          )}
        </Stack>
      </Stack>
      <NavigationButton title='Alle ansehen' link={link} endIcon={ArrowHeadRightIcon} />
    </Stack>
  );
};
