import { Theme, createTheme } from '@mui/material';
import { CSSProperties } from 'react';

import { palette } from './palette';

declare module '@mui/material/styles' {
  interface Theme {
    variables: {
      header: {
        // TODO: Fix typing, should be breakpoint value e.g. { sm: ..., md: ... }
        height: any;
      };
      podcast: {
        card: {
          width: CSSProperties['width'];
          mr: CSSProperties['marginRight'];
        };
      };
      episode: {
        card: {
          height: CSSProperties['height'];
          width: CSSProperties['width'];
          mr: CSSProperties['marginRight'];
        };
      };
      container: {
        mt: CSSProperties['marginTop'];
        mb: CSSProperties['marginBottom'];
      };
      radius: {
        base: CSSProperties['borderRadius'];
        inner: CSSProperties['borderRadius'];
        icon: CSSProperties['borderRadius'];
        chip: CSSProperties['borderRadius'];
      };
    };
  }
  interface ThemeOptions {
    variables?: {
      header?: {
        // TODO: Fix typing, should be breakpoint value e.g. { sm: ..., md: ... }
        height?: any;
      };
      podcast?: {
        card?: {
          width?: CSSProperties['width'];
          mr?: CSSProperties['marginRight'];
        };
      };
      episode?: {
        card?: {
          height?: CSSProperties['height'];
          width?: CSSProperties['width'];
          mr?: CSSProperties['marginRight'];
        };
      };
      container?: {
        mt?: CSSProperties['marginTop'];
        mb?: CSSProperties['marginBottom'];
      };
      radius?: {
        base?: CSSProperties['borderRadius'];
        inner?: CSSProperties['borderRadius'];
        icon?: CSSProperties['borderRadius'];
        chip?: CSSProperties['borderRadius'];
      };
    };
  }
}

export const themeVariables: Theme['variables'] = {
  header: {
    height: { xs: '4rem', sm: '6rem' },
  },
  podcast: {
    card: {
      width: '19rem',
      mr: '1rem',
    },
  },
  episode: {
    card: {
      height: '12rem',
      width: '19rem',
      mr: '1rem',
    },
  },
  container: {
    mt: '3rem',
    mb: '5rem',
  },
  radius: {
    base: '12px',
    inner: '8px',
    icon: '6px',
    chip: '3px',
  },
};

export const theme = createTheme({
  palette: palette,
  variables: themeVariables,
  typography: {
    fontFamily: ['Porsche'].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '600',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '600',
    },
    h4: {
      fontWeight: '600',
      fontSize: '1.2rem',
    },
    h5: {
      fontSize: '0.9rem',
      color: palette.grey[600],
    },
    h6: {
      fontSize: '0.7rem',
      textTransform: 'uppercase',
      color: palette.grey[600],
      fontWeight: '600',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.8rem',
      fontWeight: 900,
    },
    caption: {
      fontSize: '0.7rem',
      color: palette.grey[400],
    },
    button: {
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 992,
      lg: 1200,
      xl: 1440,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          hyphens: 'auto',
          whiteSpace: 'normal',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          '&.MuiContainer-maxWidthLg': {
            maxWidth: 1400,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: 'all 0.3s ease-in-out',
        },
        colorPrimary: {
          backgroundColor: palette.primary.main,
          ':hover': {
            backgroundColor: 'white',
            '& > svg': {
              fill: palette.secondary.main,
            },
          },
        },
        colorSecondary: {
          backgroundColor: palette.secondary.main,
          ':hover': {
            backgroundColor: palette.primary.main,
          },
        },
        colorInfo: {
          backgroundColor: 'white',
          '& > svg': {
            fill: palette.secondary.main,
          },
          ':hover': {
            backgroundColor: palette.primary.main,
            '& > svg': {
              fill: 'white',
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: palette.secondary.main,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        colorPrimary: {
          color: palette.primary.main,
          '& > .MuiSlider-rail': {
            color: palette.grey[400],
          },
        },
        colorSecondary: {
          color: palette.grey[400],
        },
        thumb: {
          display: 'none',
        },
        track: {
          height: '2px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'black',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          '&:not(:last-of-type)': {
            borderRadius: '50px',
          },
          '&:not(:first-of-type)': {
            borderRadius: '50px',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          backgroundColor: palette.secondary.main,
          color: palette.background.paper,
          fontWeight: 'bold',
          padding: '0.3rem 1rem 0.3rem 1rem',
          borderRadius: '50px',
          transition: 'all 0.2s ease-in-out',
          marginRight: '0.5rem',
          '&:hover': {
            backgroundColor: palette.background.paper,
            color: palette.secondary.main,
          },
          '&.Mui-selected': {
            backgroundColor: palette.background.paper,
            '&:hover': {
              backgroundColor: palette.background.paper,
            },
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
