import { Box, Stack, styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { Image, PlayButton } from 'components/common';
import { useEpisodeTimestamp, usePlayer } from 'hooks';
import { AnalyticsService } from 'lib/AnalyticsService';
import { Episode } from 'models';
import { ROUTE_CONSTANTS } from 'routes';
import { StyledImageSkeleton, StyledPre, StyledTitleLink } from 'styles/components';
import { theme } from 'styles/theme';
import { remToPx } from 'utils';

import { EpisodeActions } from './EpisodeActions';
import { EpisodeChips } from './EpisodeChips';
import { EpisodeDurationInfo } from './EpisodeDurationInfo';
import { EpisodeInfo } from './EpisodeInfo';

const StyledContainerTop = styled(Stack)(
  ({ theme }) => `
  padding: 1.5rem;
  border-top-left-radius: ${theme.variables.radius.base};
  border-top-right-radius: ${theme.variables.radius.base};
  background-color: white;
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.1);
  clip-path: inset(0px 0px -15px 0px);
  z-index: 1;
  height: 50%;
`
);

const StyledContainerBottom = styled(Stack)(
  ({ theme }) => `
  padding: 1.5rem;
  border-bottom-left-radius: ${theme.variables.radius.base};
  border-bottom-right-radius: ${theme.variables.radius.base};
  background-color: ${theme.palette.grey[50]};
  height: 50%;
`
);

const StyledTextSection = styled(Box)`
  max-height: 8rem;
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

type EpisodeInfoCardProps = {
  episode: Episode;
  isLatest: boolean;
};

export const EpisodeInfoCard = ({ episode, isLatest }: EpisodeInfoCardProps) => {
  const { player, handleClickPlay, isPlayingEpisode } = usePlayer();
  const { episodeTimestamp } = useEpisodeTimestamp(episode.id);
  return (
    <Box sx={{ mb: '2rem' }}>
      <StyledContainerTop direction={'column'}>
        <Stack direction={{ xs: 'column', sm: 'row' }} height='100%'>
          <NavLink to={`${ROUTE_CONSTANTS.EPISODE}/${episode.id}`}>
            <Image
              src={episode.image}
              alt='Episode Thumbnail'
              borderRadius={theme.variables.radius.inner}
              width='15rem'
              renderLoading={<StyledImageSkeleton sx={{ width: '15rem' }} />}
              ctfWidth={remToPx('15rem')}
            />
          </NavLink>

          <Stack justifyContent='space-between' sx={{ ml: '1rem', flex: 1 }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ mt: { xs: '1rem', sm: 0 } }}
            >
              <EpisodeChips episodeNr={episode.episodeNumber} isLatest={isLatest} />
              <EpisodeActions episodeId={episode.id} />
            </Stack>

            <StyledTitleLink to={`${ROUTE_CONSTANTS.EPISODE}/${episode.id}`} sx={{ my: '1rem' }}>
              <Typography variant='h4'>{episode.title}</Typography>
            </StyledTitleLink>

            <Stack direction='row' alignItems='center'>
              <PlayButton
                episodeId={episode.id}
                color='secondary'
                sx={{ mr: '1rem' }}
                onClick={() => {
                  !episodeTimestamp && AnalyticsService.trackEpisodeStart(episode);
                  handleClickPlay(episode.id);
                }}
              />
              <EpisodeDurationInfo
                lengthSeconds={episode.lengthSeconds}
                hasPlayed={isPlayingEpisode(episode.id) ? player.progress : episodeTimestamp}
              />
            </Stack>
          </Stack>
        </Stack>
      </StyledContainerTop>

      <StyledContainerBottom>
        <EpisodeInfo
          createdAt={episode.createdAt}
          author={episode.author}
          language={episode.language}
        />
        <StyledTextSection>
          <StyledPre>{episode.description}</StyledPre>
        </StyledTextSection>
        <StyledTitleLink
          to={`${ROUTE_CONSTANTS.EPISODE}/${episode.id}`}
          color={theme.palette.primary.main}
        >
          {'Mehr erfahren'}
        </StyledTitleLink>
      </StyledContainerBottom>
    </Box>
  );
};
