import { Fragment } from 'react';

import { useLatestEpisodes } from 'api/useLatestEpisodes';
import { EpisodeCard } from 'components/episode';
import { HorizontalScroll } from 'components/scroll';
import { HorizontalScrollLoading } from 'components/scroll/loading';

export const DiscoverLatestEpisodes = () => {
  const { data: latestEpisodes = [], isLoading } = useLatestEpisodes();

  if (isLoading)
    return (
      <Fragment>
        <HorizontalScrollLoading />
      </Fragment>
    );

  return (
    <HorizontalScroll>
      {latestEpisodes.map((episode) => (
        <EpisodeCard key={episode.id} episode={episode} />
      ))}
    </HorizontalScroll>
  );
};
