import { Box } from '@mui/material';
import { ReactNode } from 'react';

type GridContainerProps = {
  children?: ReactNode;
};

export const GridContainer = ({ children }: GridContainerProps) => {
  return (
    <Box
      display='grid'
      gap={2}
      justifyContent='center'
      sx={{
        gridTemplateColumns: { sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' },
      }}
    >
      {children}
    </Box>
  );
};
