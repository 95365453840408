import Axios, { AxiosRequestConfig } from 'axios';

import { APIError } from 'api/error';
import { environment as env } from 'environments';

import { fetchMsalToken } from './authConfig';

const getIdTokenViaMsal = async () => await fetchMsalToken().then((token) => token.idToken);

async function authRequestInterceptor(config: AxiosRequestConfig) {
  if (!config || !env.msal.active) {
    config = {};
  }
  if (!config.headers) {
    config.headers = {};
  }
  config.headers.Authorization = `Bearer ${await getIdTokenViaMsal()}`;
  config.headers.Accept = 'application/json';
  config.headers['X-PODCAST-WEB'] = 1;
  config.headers['X-PORSCHE-CLIENT-ID'] = env.api.clientId;
  config.headers['X-PORSCHE-CLIENT-SECRET'] = env.api.clientSecret;
  return config;
}

export const AXIOS_INSTANCE = Axios.create({
  baseURL: env.api.url,
  timeout: 5000,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
AXIOS_INSTANCE.interceptors.request.use(authRequestInterceptor);

const reload = () => {
  window.location.replace(window.location.href);
};

AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    if (response.data.errors.length > 0) {
      return Promise.reject(new APIError(response.data.errors));
    }
    return response;
  },
  async (error) => {
    const message = error.response?.data?.message || error.message;
    console.error(message);

    if (error.response?.status == 303) {
      reload();
      return;
    }
    return Promise.reject(message);
  }
);

export const customAxios = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};
