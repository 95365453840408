import packageJson from '../../package.json';

export type MsalConfig = {
  clientId: string;
  authority: string;
  redirectUri: string;
  postLogoutRedirectUri: string;
  loginRequestScopes: string;
  browserLoggingEnabled: boolean;
  active: boolean;
  logLevel: string;
};

export type ApiConfig = {
  url: string;
  clientId: string;
  clientSecret: string;
};

export type Environment = {
  productName: string;
  version: string;
  build: string;
  release: string;
  environment: string;
  api: ApiConfig;
  tenant: string;
  msal: MsalConfig;
};

export const baseEnvironment: Pick<Environment, 'productName' | 'version'> = {
  productName: 'PAG-podcast',
  version: packageJson.version,
};
