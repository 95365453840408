import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { Episode } from 'models';
import { bookmarkState } from 'store';
import { LOCAL_STORAGE_CONSTANTS, storagePrefix } from 'utils';

import { useLocalStorage } from './useLocalStorage';

export const useEpsiodeBookmark = () => {
  const [bookmarks, setBookmarks] = useRecoilState(bookmarkState);
  const [episodeBookmarks, setEpisodeBookmarks] = useLocalStorage<string[]>(
    `${storagePrefix}${LOCAL_STORAGE_CONSTANTS.EPISODE_BOOKMARK}`,
    []
  );

  useEffect(() => {
    setBookmarks(new Set(episodeBookmarks));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeBookmarks]);

  const isBookmarked = (episodeId: Episode['id']) =>
    bookmarks instanceof Set ? bookmarks.has(episodeId) : false;

  const toggleEpisodeBookmark = (episodeId: Episode['id']) => {
    setEpisodeBookmarks(() => {
      bookmarks.has(episodeId) ? bookmarks.delete(episodeId) : bookmarks.add(episodeId);
      return Array.from(bookmarks);
    });
  };

  return { bookmarks, isBookmarked, toggleEpisodeBookmark };
};
