import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { ReactComponent as ArrowHeadLeftIcon } from 'assets/icon/arrow-head-left.svg';

import { ScrollControl } from './ScrollControl';

export const ScrollPrevArrow = () => {
  const { isFirstItemVisible, getPrevElement, scrollToItem, initComplete } =
    React.useContext(VisibilityContext);

  return initComplete ? (
    <ScrollControl
      visible={isFirstItemVisible}
      onClick={() => scrollToItem(getPrevElement(), 'smooth', 'start')}
      sx={{ left: '2rem' }}
    >
      <ArrowHeadLeftIcon />
    </ScrollControl>
  ) : (
    <></>
  );
};
