import { atom } from 'recoil';

import { Player } from 'models';

export const defaultPlayer = {
  nowPlayingId: null,
  isReady: false,
  isPlaying: true,
  isSeeking: false,
  isLoading: true,
  progress: {
    loaded: 0,
    loadedSeconds: 0,
    played: 0,
    playedSeconds: 0,
  },
} as const;

export const playerState = atom<Player>({
  key: 'player',
  default: defaultPlayer,
});
