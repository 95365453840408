import { Box, Button, Divider, DividerProps, Grid, styled, Typography } from '@mui/material';

import { ReactComponent as BookmarkIconFilled } from 'assets/icon/bookmark-filled.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icon/bookmark.svg';
import { ReactComponent as StackIcon } from 'assets/icon/stack.svg';
import { PlayButtonIcons } from 'components/common';
import { useEpisodeTimestamp, useEpsiodeBookmark, usePlayer } from 'hooks';
import { AnalyticsService } from 'lib/AnalyticsService';
import { Episode, LatestEpisode } from 'models';
import { ROUTE_CONSTANTS } from 'routes';
import { theme } from 'styles/theme';

const StyledContainer = styled(Box)`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-wrap: nowrap;
`;

const StyledPlayIconsWrapper = styled(Box)`
  width: 2.75rem;
  height: 2.75rem;
  background-color: ${theme.palette.primary.main};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
  margin: 0.5rem;
  padding: 0;
  p {
    margin-top: 0.5rem;
    color: white;
  }
  svg:not(.play-icon, .pause-icon) {
    margin-top: 0.3rem;
    fill: white;
    width: 36px;
    height: 36px;
  }
  &:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    p {
      color: ${theme.palette.primary.main};
    }
    svg:not(.play-icon, .pause-icon) {
      fill: ${theme.palette.primary.main};
    }
  }
`;

const StyledDivider = styled(({ ...props }: DividerProps) => (
  <Divider {...props} orientation='vertical' variant='middle' flexItem />
))`
  border-color: rgba(255, 255, 255, 0.3);
`;

type EpisodeCardActionsProps = {
  episode: LatestEpisode | Episode;
  podcastId: string;
};

export const EpisodeCardActions = ({ episode, podcastId }: EpisodeCardActionsProps) => {
  const { handleClickPlay, isPlayingEpisode } = usePlayer();
  const { isBookmarked, toggleEpisodeBookmark } = useEpsiodeBookmark();
  const { episodeTimestamp } = useEpisodeTimestamp(episode.id);

  return (
    <StyledContainer className='cardActions' data-test='episode-actions'>
      <Grid container spacing={0}>
        <StyledActionButton
          onClick={() => {
            !episodeTimestamp && AnalyticsService.trackEpisodeStart(episode);
            handleClickPlay(episode.id);
          }}
          data-test='episode-play-button'
        >
          <StyledPlayIconsWrapper>
            <PlayButtonIcons episodeId={episode.id} />
          </StyledPlayIconsWrapper>
          <Typography variant='body2'>
            {isPlayingEpisode(episode.id) ? 'Stoppen' : 'Abspielen'}
          </Typography>
        </StyledActionButton>
        <StyledDivider />
        <StyledActionButton
          onClick={() => toggleEpisodeBookmark(episode.id)}
          data-test='episode-bookmark-button'
        >
          {isBookmarked(episode.id) ? <BookmarkIconFilled /> : <BookmarkIcon />}
          <Typography variant='body2'>{isBookmarked(episode.id) ? 'Gemerkt' : 'Merken'}</Typography>
        </StyledActionButton>
        <StyledDivider />
        <StyledActionButton
          href={`${ROUTE_CONSTANTS.PODCAST}/${podcastId}`}
          data-test='episode-podcast-link'
        >
          <StackIcon />
          <Typography variant='body2'>Alle Episoden</Typography>
        </StyledActionButton>
      </Grid>
    </StyledContainer>
  );
};
