import { useState } from 'react';

type UseStepProps = {
  start: number;
  min: number;
  max: number;
  step: number;
};

export const useStep = ({ start, min, max, step }: UseStepProps) => {
  const [value, setValue] = useState<number>(start);

  const getNextValue = () => {
    const updatedValue = value + step;
    if (updatedValue > max) return min;
    return updatedValue;
  };

  const nextValue = () => setValue(getNextValue());

  return { value, nextValue };
};
