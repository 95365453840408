import { useQuery } from 'react-query';

import { queryClient, queryKeys } from 'lib/react-query';
import { getPodcastApp } from 'proxy';
import { toCategoryPodcasts } from 'services';

export const getCategoryPodcasts = () =>
  getPodcastApp().getContentfulSuggestedCategoryPodcastsUsingGET();

export const useCategoryPodcasts = () => {
  return useQuery(queryKeys.category.podcasts(), getCategoryPodcasts, {
    initialData: queryClient.getQueryData(queryKeys.category.podcasts()),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.category.podcasts())?.dataUpdatedAt,
    select: (data) => data.data?.map(toCategoryPodcasts),
  });
};
