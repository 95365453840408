import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';

import { useFAQ } from 'api';
import { ReactComponent as ArrowHeadDownIcon } from 'assets/icon/arrow-head-down.svg';
import { StyledContainer } from 'styles/components';
import { groupBy } from 'utils';

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:is(:first-of-type)': {
    borderTopLeftRadius: theme.variables.radius.base,
    borderTopRightRadius: theme.variables.radius.base,
  },
  '&:is(:last-of-type)': {
    borderBottomLeftRadius: theme.variables.radius.base,
    borderBottomRightRadius: theme.variables.radius.base,
  },
  '&:not(:last-of-type)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const FAQ = () => {
  const { data: faqs = [], isLoading } = useFAQ();
  const groupedFAQ = groupBy(faqs, (faq) => faq.type);

  return (
    <Fragment>
      {isLoading ? (
        <StyledContainer sx={{ display: 'grid', placeItems: 'center' }}>
          <CircularProgress />
        </StyledContainer>
      ) : (
        <StyledContainer>
          {Object.keys(groupedFAQ).map((key, index) => (
            <Box key={index} sx={{ mb: 4 }}>
              <Typography variant='h3'>{key}</Typography>
              <Box sx={{ my: 2 }}>
                {groupedFAQ[key].map((faq) => (
                  <StyledAccordion key={faq.id}>
                    <AccordionSummary
                      expandIcon={<ArrowHeadDownIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                  </StyledAccordion>
                ))}
              </Box>
            </Box>
          ))}
        </StyledContainer>
      )}
    </Fragment>
  );
};
