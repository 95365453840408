import { Skeleton, Stack, Typography } from '@mui/material';

import { StyledTitleLink } from 'styles/components';
import { theme } from 'styles/theme';

type HorizontalScrollTitleLoadingProps = {
  withIcon?: boolean;
};

export const HorizontalScrollTitleLoading = ({
  withIcon = false,
}: HorizontalScrollTitleLoadingProps) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' mt={2} mb={1}>
      <Stack direction='row' alignItems='center'>
        {withIcon && (
          <Skeleton
            variant='rectangular'
            width='3rem'
            height='3rem'
            sx={{ borderRadius: theme.variables.radius.icon }}
          />
        )}
        <StyledTitleLink to={'/loading'}>
          <Typography variant='h3' marginLeft={withIcon ? 2 : 0}>
            <Skeleton height='2rem' width='8rem' />
          </Typography>
        </StyledTitleLink>
      </Stack>
      <Skeleton height='2rem' width='8rem' />
    </Stack>
  );
};
