import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { ItemType } from '../../../node_modules/react-horizontal-scrolling-menu/dist/types/types';

import { ScrollNextArrow } from './ScrollNextArrow';
import { ScrollPrevArrow } from './ScrollPrevArrow';

type HorizontalScrollProps = {
  children?: ItemType | ItemType[];
  disableControls?: boolean;
};

export const HorizontalScroll = ({ children, disableControls = false }: HorizontalScrollProps) => {
  return (
    <ScrollMenu
      {...(!disableControls && { LeftArrow: <ScrollPrevArrow /> })}
      {...(!disableControls && { RightArrow: <ScrollNextArrow /> })}
      wrapperClassName='custom-horizontal-scroll'
    >
      {children as ItemType}
    </ScrollMenu>
  );
};
