import { Box, Container } from '@mui/material';
import { ReactNode } from 'react';

import waves from 'assets/img/discoverHeaderWave.svg';
import { StyledBgContainer } from 'styles/components';
import { theme } from 'styles/theme';

type HeadingContainerProps = {
  children: ReactNode;
  withContainer?: boolean;
};

export const HeadingContainer = ({ children, withContainer = true }: HeadingContainerProps) => {
  return (
    <StyledBgContainer bgcolor={theme.palette.secondary.main} bgImage={waves} color='white'>
      {withContainer ? (
        <Container sx={{ zIndex: 1, position: 'relative' }}>{children}</Container>
      ) : (
        <Box sx={{ zIndex: 1, position: 'relative', pl: '3rem' }}>{children}</Box>
      )}
    </StyledBgContainer>
  );
};
