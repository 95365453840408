import createPalette from '@mui/material/styles/createPalette';

export const palette = createPalette({
  primary: {
    main: '#D5001C',
  },
  secondary: {
    main: '#0E1418',
  },
  success: {
    main: '#018A16',
  },
  warning: {
    main: '#FF9B00',
  },
  error: {
    main: '#E00000',
  },
  background: {
    default: '#f1f1f2',
    paper: '#FFFFFF',
  },
  text: {
    primary: '#000000',
    secondary: '#e3e4e5',
  },
});
