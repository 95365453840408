import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { AnalyticsService } from 'lib/AnalyticsService';
import { queryClient, queryKeys } from 'lib/react-query';
import { Episode } from 'models';
import { GetContentfulEpisodesByPodcastUsingGETParams, getPodcastApp } from 'proxy';
import { ROUTE_CONSTANTS } from 'routes';
import { toEpisode } from 'services';

export const getEpisode = (params: GetContentfulEpisodesByPodcastUsingGETParams) =>
  getPodcastApp().getContentfulEpisodesByPodcastUsingGET(params);

export const useEpisode = (episodeId: string) => {
  const location = useLocation();
  return useQuery(queryKeys.episode.detail(episodeId), () => getEpisode({ id: episodeId }), {
    initialData: queryClient.getQueryData(queryKeys.episode.detail(episodeId)),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.episode.detail(episodeId))
      ?.dataUpdatedAt,
    select: (data) => data?.data && toEpisode(data?.data?.[0]),
    enabled: !!episodeId,
    onSuccess: (episode: Episode | undefined) => {
      if (episode && location.pathname.includes(ROUTE_CONSTANTS.EPISODE)) {
        AnalyticsService.trackEpisodeOpen(episode);
      }
    },
  });
};
