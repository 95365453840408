import { CircularProgress, Stack } from '@mui/material';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { useEpisode } from 'api';
import { Episode } from 'models';

import { EpisodeDetailsContent } from './EpisodeDetailsContent';
import { EpisodeDetailsHeader } from './EpisodeDetailsHeader';
import { EpisodeDetailsRelated } from './EpisodeDetailsRelated';

export const EpisodeDetail = () => {
  const { id } = useParams();
  const { data: episode = {} as Episode, isLoading } = useEpisode(id ?? '');

  if (isLoading) {
    return (
      <Stack alignItems='center' sx={{ marginTop: '5rem' }}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Fragment>
      <EpisodeDetailsHeader episode={episode} />
      <EpisodeDetailsContent episode={episode} />
      <EpisodeDetailsRelated episode={episode} />
    </Fragment>
  );
};
