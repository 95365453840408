import { Grid, Link, Typography } from '@mui/material';
import { Fragment } from 'react';

import { HeadingContainer } from 'components/layout';
import { StyledContainer } from 'styles/components';
import { theme } from 'styles/theme';

export const Imprint = () => {
  return (
    <Fragment>
      <HeadingContainer>
        <Typography variant='h1'>Impressum</Typography>
      </HeadingContainer>
      <StyledContainer>
        <Grid container spacing={2}>
          <Grid md={12} lg={6}>
            <p>
              <strong>Dr. Ing. h.c. F. Porsche AG</strong>
              <br />
              Porscheplatz 1 <br />
              D-70435 Stuttgart
              <br />
              <br />
            </p>
            <p>
              <strong>E-Mail:</strong> <Link href='mailto:info@porsche.de'>info@porsche.de</Link>
              <br />
              <strong>Tel:</strong> (+49) 0711 911-0
              <br />
              <br />
            </p>
            <p>
              <strong>Vertreten durch den Vorstand:</strong>
              <br />
              Oliver Blume, Vorsitzender | Lutz Meschke, Stv. Vorsitzender
              <br />
              Andreas Haffner, Albrecht Reimold, Barbara Frenkel
              <br />
              Detlev von Platen, Michael Steiner, Sajjad Khan
              <br />
              <br />
            </p>
            <p>
              Registergericht: Amtsgericht Stuttgart
              <br />
              HRB-Nr. 730623
              <br />
              USt.-Ident.-Nr. DE 147 799 625
            </p>
            <br />
            <br />
            <p>
              <strong>Verbraucherinformation gemäß Verordnung EU Nr. 524/2013</strong>
              <br />
              <br />
              Die Europäische Kommission stellt eine Plattform zur{' '}
              <strong>Online-Streitbeilegung (OS)</strong> bereit. Die
              <br />
              Plattform finden Sie unter{' '}
              <Link href='https://ec.europa.eu/consumers/odr' color={theme.palette.text.primary}>
                https://ec.europa.eu/consumers/odr
              </Link>
            </p>
            <p>
              <strong>Verbraucherinformation gemäß Verbraucherstreitbeilegungsgesetz</strong>
              <br />
              <br />
              Die Porsche Deutschland GmbH und die Dr. Ing. h.c. F. Porsche AG sind nicht bereit und
              verpflichtet, an <br />
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
              <br />
              <br />
            </p>
            <p>
              <strong>Rechtlicher Hinweis</strong>
              <br />
              <br />
              Die Texte, Bilder, Grafiken, Animationen, Video- und Audiodateien sowie alle weiteren
              Inhalte auf dieser Webseite unterliegen den gesetzlichen Bestimmungen des
              Urheberrechts und ggf. weiteren Rechten des geistigen Eigentums. Der geschützte Inhalt
              dieser Webseite darf ohne Einwilligung des Rechtsinhabers nicht vervielfältigt,
              verbreitet, wiedergegeben, öffentlich zugänglich gemacht oder sonst verwertet werden.
              Änderungen von Konstruktion, Ausstattung und Lieferumfang sowie Abweichungen im
              Farbton sowie Irrtümer bleiben vorbehalten. Alle angegebenen Preise sind
              unverbindlich.
            </p>
          </Grid>
          <Grid md={12} lg={6}>
            <p>
              <strong>Porsche Deutschland GmbH</strong>
              <br />
              Porschestraße 1<br />
              D-74321 Bietigheim-Bissingen
              <br />
              <br />
            </p>
            <p>
              <strong>E-Mail:</strong> <Link href='mailto:info@porsche.de'>info@porsche.de</Link>
              <br />
              <strong>Tel:</strong> (+49) 0711 911 122-50
              <br />
              <br />
            </p>
            <p>
              <strong>Vertreten durch die Geschäftsführer:</strong>
              <br />
              Alexander Pollich (Vorsitzender)
              <br />
              Karsten Sohns
              <br />
              <br />
            </p>
            <p>
              Sitz der Gesellschaft: Bietigheim-Bissingen
              <br />
              Registergericht: Amtsgericht Stuttgart
              <br />
              HRB-Nr. 301917
              <br />
              USt.-Ident.-Nr. DE 812 949 278
            </p>
            <br />
            <br />
          </Grid>
        </Grid>
      </StyledContainer>
    </Fragment>
  );
};
