import { useState } from 'react';

export const useVolume = () => {
  const [volume, setVolume] = useState<number>(30);
  const [cachedVolume, setCachedVolume] = useState<number>(30);

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    setVolume(newValue as number);
  };

  const handleVolumeOn = () => {
    setCachedVolume(volume);
    setVolume(0);
  };

  const handleVolumeOff = () => {
    setVolume(cachedVolume);
  };
  return { volume, handleVolumeChange, handleVolumeOn, handleVolumeOff };
};
