import { Alert, AlertTitle, Button, Grid, Stack, styled, SvgIcon, Typography } from '@mui/material';
import { Fragment } from 'react';

import { APIError } from 'api/error';
import { ReactComponent as ArrowHeadLeft } from 'assets/icon/arrow-head-left.svg';
import { ReactComponent as ReplayIcon } from 'assets/icon/replay.svg';
import { ErrorResponse } from 'proxy';
import { ROUTE_CONSTANTS } from 'routes';
import { storage } from 'utils';

const StyledAlert = styled(Alert)(
  ({ theme }) =>
    `
    color: ${theme.palette.secondary.main};
    border-radius: ${theme.variables.radius.base};
    background-color: white;
    & .MuiAlertTitle-root {
        color: ${theme.palette.error.main};
    }
    `
);

export const resetErrorBoundary = () => {
  storage.removeAllTokens();
  window.location.href = '/';
};

type ErrorFallbackProps = {
  error: ErrorResponse | Error | string;
  resetErrorBoundary: () => void;
};

export const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  const redirectHome = () => {
    window.location.replace(ROUTE_CONSTANTS.DISCOVER);
  };

  const renderError = () => {
    if (error instanceof APIError) {
      return (
        <Fragment>
          {error.errors.map((error) => (
            <StyledAlert key={error.httpStatusCode} severity='error' sx={{ mt: 2 }}>
              <AlertTitle sx={{ fontWeight: 'bold' }}>
                {error.httpStatusCode} {error.httpStatus}
              </AlertTitle>
              <Typography>{error.message}</Typography>
            </StyledAlert>
          ))}
        </Fragment>
      );
    }
    if (error instanceof Object) {
      return (
        <StyledAlert severity='error' sx={{ mt: 2 }}>
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </StyledAlert>
      );
    }
    return (
      <StyledAlert severity='error' sx={{ mt: 2 }}>
        <AlertTitle>Error</AlertTitle>
        {error}
      </StyledAlert>
    );
  };

  return (
    <Grid container justifyContent='center' alignItems='center' height='100vh' direction='column'>
      <Stack direction='column' sx={{ mb: 4 }}>
        <Typography variant='h2'>Ein Fehler ist aufgetreten</Typography>
        {renderError()}
      </Stack>

      <Stack direction='column' sx={{ textAlign: 'center' }}>
        <Button
          variant='contained'
          onClick={() => {
            resetErrorBoundary();
            redirectHome();
          }}
          startIcon={<ReplayIcon />}
          sx={{ my: 1 }}
        >
          App neu laden
        </Button>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => redirectHome()}
          startIcon={<SvgIcon component={ArrowHeadLeft} sx={{ fill: 'white' }} />}
        >
          Zur Startseite
        </Button>
      </Stack>
    </Grid>
  );
};
