import { Box, Divider, Link, Menu, styled, SvgIcon, Typography } from '@mui/material';

import { ReactComponent as LightIcon } from 'assets/icon/light.svg';
import { ReactComponent as RacingFlagIcon } from 'assets/icon/racing-flag.svg';
import { useModal } from 'hooks';
import { useUser } from 'lib';
import { ROUTE_CONSTANTS } from 'routes';
import { theme } from 'styles/theme';

import { LogoutButton } from './LogoutButton';
import { NavigationButton, StyledNavItem } from './NavigationButton';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${theme.palette.secondary.main};
  border-radius: 2px;
  padding: 0.3rem 1rem;
  display: block;
  :hover {
    background-color: ${theme.palette.grey[200]};
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;

export type HeaderMenuProps = {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
};

// TODO: get user details
export const HeaderMenu = ({ anchorEl, handleClose }: HeaderMenuProps) => {
  const open = Boolean(anchorEl);
  const { showModal } = useModal();
  const username = useUser();

  const handleOnboardingClick = () => {
    showModal({
      modalType: 'OnboardModal',
    });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id='account-menu'
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: theme.variables.radius.base,
          filter: 'drop-shadow(0px 0px 5px rgba(0,0,0,0.1))',
          minWidth: '15rem',
          mt: 1.5,
          '& .MuiList-root': {
            py: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Box sx={{ p: 3 }}>
        <Typography variant='h4'>{username}</Typography>
      </Box>
      <Divider />
      <Box sx={{ py: 2, px: 1 }}>
        <Typography variant='h6' sx={{ mb: 1, px: 2 }}>
          Einstellungen
        </Typography>
        <StyledNavItem disableRipple onClick={() => handleOnboardingClick()}>
          <SvgIcon component={RacingFlagIcon} sx={{ mr: 1 }}></SvgIcon>
          App Onboarding erneut ansehen
        </StyledNavItem>
      </Box>
      <Divider />
      <Box sx={{ py: 2, px: 1 }}>
        <Typography variant='h6' sx={{ mb: 1, px: 2 }}>
          Support
        </Typography>
        <NavigationButton title='FAQ' startIcon={LightIcon} link={ROUTE_CONSTANTS.HELP_PAGE} />
      </Box>
      <Divider />
      <Box sx={{ py: 2, px: 1 }}>
        <Typography variant='h6' sx={{ mb: 1, px: 2 }}>
          Legal
        </Typography>
        <NavigationButton title='Impressum' link={ROUTE_CONSTANTS.IMPRINT} />
        <StyledLink href={`${window.location.origin}/porsche_legals.pdf`} target='_blank'>
          Datenschutz
        </StyledLink>
        <NavigationButton title='Nutzungsbedingungen' link={ROUTE_CONSTANTS.TERMS_OF_USE} />
      </Box>
      <Divider />
      <Box sx={{ p: 3 }}>
        <LogoutButton />
      </Box>
    </Menu>
  );
};
