import { CircularProgress } from '@mui/material';
import { Fragment } from 'react';

import { ReactComponent as PauseIcon } from 'assets/icon/pause.svg';
import { ReactComponent as PlayIcon } from 'assets/icon/play.svg';
import { usePlayer } from 'hooks';
import { Episode } from 'models';

type PlayButtonProps = {
  episodeId: Episode['id'];
};

export const PlayButtonIcons = ({ episodeId }: PlayButtonProps) => {
  const { isLoadingEpisode, isPlayingEpisode } = usePlayer();
  return (
    <Fragment>
      {isLoadingEpisode(episodeId) ? (
        <CircularProgress size='100%' data-test='play-loading' />
      ) : isPlayingEpisode(episodeId) ? (
        <PauseIcon fill='#fff' width='28px' height='28px' className='play-icon' />
      ) : (
        <PlayIcon fill='#fff' width='28px' height='28px' className='pause-icon' />
      )}
    </Fragment>
  );
};
