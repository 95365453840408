import toast from 'react-hot-toast';
import { DefaultOptions, QueryCache, QueryClient, UseQueryOptions } from 'react-query';
import { PromiseValue } from 'type-fest';

import { APIError } from 'api/error';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: (error: any) => {
      if (error instanceof APIError) return true;
      if (error instanceof Error) return error.message.includes('Network');
      return error.includes('Network');
    },
    refetchOnWindowFocus: false,
    staleTime: 10000,
    retry: 3,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  queryCache: new QueryCache({
    onError: (error: unknown, query) => {
      // Check if error is of type Error
      if (error instanceof Error) {
        // 🎉 only show error toasts if we already have data in the cache
        // which indicates a failed background update
        if (query.state.data !== undefined) {
          toast.error(`Something went wrong: ${error.message}`);
        }
      }
    },
  }),
});

export type QueryConfig<FetcherFnType extends (...args: any) => any> = UseQueryOptions<
  PromiseValue<ReturnType<FetcherFnType>>
>;

// Query Key factories => https://tkdodo.eu/blog/effective-react-query-keys
export const queryKeys = {
  category: {
    all: ['category'] as const,
    lists: () => [...queryKeys.category.all, 'list'] as const,
    list: (filters: string) => [...queryKeys.category.lists(), { filters }] as const,
    details: () => [...queryKeys.category.all, 'detail'] as const,
    detail: (id: string) => [...queryKeys.category.details(), id] as const,
    episodes: () => [...queryKeys.category.details(), 'episodes'] as const,
    podcasts: () => [...queryKeys.category.details(), 'podcasts'] as const,
  },
  podcast: {
    all: ['podcast'] as const,
    lists: () => [...queryKeys.podcast.all, 'list'] as const,
    list: (filters: string) => [...queryKeys.podcast.lists(), { filters }] as const,
    details: () => [...queryKeys.podcast.all, 'detail'] as const,
    detail: (id: string) => [...queryKeys.podcast.details(), id] as const,
  },
  episode: {
    all: ['episode'] as const,
    lists: () => [...queryKeys.episode.all, 'list'] as const,
    list: (filters: string) => [...queryKeys.episode.lists(), { filters }] as const,
    details: () => [...queryKeys.episode.all, 'detail'] as const,
    detail: (id: string) => [...queryKeys.episode.details(), id] as const,
    latest: () => [...queryKeys.episode.all, 'latest'] as const,
  },
  search: {
    all: ['search'] as const,
    lists: () => [...queryKeys.search.all, 'list'] as const,
    list: (keyword: string) => [...queryKeys.search.lists(), { keyword }] as const,
  },
  faq: {
    all: ['faq'] as const,
  },
};
