import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { AnalyticsService } from 'lib/AnalyticsService';
import { queryClient, queryKeys } from 'lib/react-query';
import { Podcast } from 'models';
import { GetContentfulPodcastsByCategoryUsingGETParams, getPodcastApp } from 'proxy';
import { ROUTE_CONSTANTS } from 'routes';
import { toPodcast } from 'services';

export const getPodcast = (params: GetContentfulPodcastsByCategoryUsingGETParams) =>
  getPodcastApp().getContentfulPodcastsByCategoryUsingGET(params);

export const usePodcast = (podcastId: string) => {
  const location = useLocation();
  return useQuery(queryKeys.podcast.detail(podcastId), () => getPodcast({ id: podcastId }), {
    initialData: queryClient.getQueryData(queryKeys.podcast.detail(podcastId)),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.podcast.detail(podcastId))
      ?.dataUpdatedAt,
    select: (data) => data && data.data && toPodcast(data.data[0]),
    enabled: !!podcastId,
    onSuccess: (podcast: Podcast | undefined) => {
      if (podcast && location.pathname.includes(ROUTE_CONSTANTS.PODCAST)) {
        AnalyticsService.trackPodcastOpen(podcast);
      }
    },
  });
};
