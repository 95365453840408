import { FC, ReactNode } from 'react';

import { BaseLayout } from './BaseLayout';

export const withLayout = <P,>(Component: FC<P>, layoutProps: P = {} as P) => {
  const CustomLayout = (PageComponent: ReactNode) => (
    <Component {...layoutProps}>{PageComponent}</Component>
  );
  return CustomLayout;
};

export const MainLayout = withLayout(BaseLayout, { withContainer: false });
export const MainLayoutContainer = withLayout(BaseLayout);
export const StaticLayout = withLayout(BaseLayout, {
  styles: { backgroundColor: 'white' },
  withHeader: false,
  withContainer: false,
  withFooter: false,
});
