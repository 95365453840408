import { FullTextSearch } from 'models';
import { FullTextSearchResponse } from 'proxy';

export const toSearch = (data: FullTextSearchResponse): FullTextSearch => ({
  id: data.id,
  content_type: data.content_type as FullTextSearch['content_type'],
  author: data.author,
  title: data.title ?? 'Kein Titel',
  description: data.description ?? 'Keine Beschreibung',
});
