import {
  AccountInfo,
  AuthenticationResult,
  BrowserAuthError,
  BrowserSystemOptions,
  Configuration,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useMsal as useMsalReact } from '@azure/msal-react';

import { environment } from '../environments';

export const { msal } = environment;
const { clientId, authority, redirectUri, browserLoggingEnabled, loginRequestScopes } = msal;

const logLevel = (): LogLevel => {
  switch (msal.logLevel) {
    case 'trace':
      return LogLevel.Trace;
    case 'verbose':
      return LogLevel.Verbose;
    case 'warning':
      return LogLevel.Warning;
    case 'error':
      return LogLevel.Error;
    default:
      return LogLevel.Info;
  }
};

const system: BrowserSystemOptions = {
  ...(browserLoggingEnabled && {
    loggerOptions: {
      logLevel: logLevel(),
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  }),
};

export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority,
    redirectUri,
    // postLogoutRedirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system,
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: loginRequestScopes.trim().split(/\s+/),
};

export const msalInstance = new PublicClientApplication(msalConfig);
const request = {
  ...loginRequest,
  account: msalInstance.getAllAccounts()[0],
};

export const getUserId = (): string | undefined =>
  msalInstance.getAllAccounts()[0]?.idTokenClaims?.oid;

export const fetchMsalToken = (): Promise<AuthenticationResult> => {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  return msalInstance
    .acquireTokenSilent(request)
    .then((accessTokenResponse) => accessTokenResponse)
    .catch((error) => {
      console.log(error);
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(request);
      }
      if (error instanceof BrowserAuthError) {
        msalInstance.logoutRedirect({
          account: msalInstance.getAccountByHomeId(msalInstance.getAllAccounts()[0].homeAccountId),
        });
      }
      return msalInstance.acquireTokenSilent(request);
    });
};

const useMsal = (): ReturnType<typeof useMsalReact> => {
  if (msal.active) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMsalReact();
  } else {
    const dummyMsal = {
      accounts: [] as AccountInfo[],
      inProgress: false,
      instance: null,
      error: null,
    };

    return dummyMsal as unknown as ReturnType<typeof useMsalReact>;
  }
};

export default useMsal;
