import { Box, styled } from '@mui/material';

import { excludeProps } from 'utils';

type StyledBgContainerProps = {
  bgImage?: string;
};

export const StyledBgContainer = styled(Box, {
  shouldForwardProp: (props) => excludeProps<StyledBgContainerProps>(['bgImage'], props),
})<StyledBgContainerProps>(({ theme, bgImage }) => ({
  paddingTop: theme.variables.container.mt,
  paddingBottom: '3rem',
  position: 'relative',
  ...(bgImage && {
    [':before']: {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${bgImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
      },
    },
  }),
}));
