import { FAQ } from 'models';
import { FAQsResponse } from 'proxy';

export const toFAQ = (data: FAQsResponse): FAQ => ({
  id: data.id ?? '',
  answer: data.answer ?? '',
  number: data.number ?? 0,
  question: data.question ?? '',
  type: data.type ?? '',
});
