import { CloseRounded } from '@mui/icons-material';
import { Fade, IconButton, Link, Paper, Stack, SvgIcon, Typography, alpha } from '@mui/material';

import { ReactComponent as PodcastLogo } from 'assets/img/podcast-logo.svg';
import { useModal } from 'hooks/useModal';
import { theme } from 'styles/theme';

export type AppBannerProps = {
  handleClose?: () => void;
};

export const AppBanner = ({ handleClose }: AppBannerProps) => {
  const { hideModal } = useModal();

  const onClose = () => {
    handleClose && handleClose();
    hideModal();
  };

  return (
    <Fade appear={false} in={true}>
      <Paper
        role='dialog'
        aria-modal='false'
        aria-label='App download banner'
        square
        variant='outlined'
        tabIndex={-1}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          m: 0,
          p: 2,
          border: 'none',
          zIndex: 999,
          margin: 2,
          borderRadius: '18px',
          //   background: 'linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(213, 0, 28, 1) 74%)',
          background: theme.palette.primary.main,
          //   background: 'rgba(0, 0, 0, 0.7)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0px 2px 8px -2px rgba(0, 0, 0, 0.7)',
        }}
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' gap={2}>
          <IconButton
            onClick={onClose}
            color='primary'
            sx={{
              backgroundColor: alpha(theme.palette.secondary.main, 0.7),
              color: 'white',
              position: 'absolute',
              right: '-0.8rem',
              top: '-1rem',
              ':hover': {
                color: 'white',
              },
            }}
          >
            <CloseRounded />
          </IconButton>
          <Link href='https://mdm20.porsche.com:7443/mifs/asfV3x/appstore' target='_blank'>
            <Stack direction='row' gap={2} alignItems='center'>
              <SvgIcon
                component={PodcastLogo}
                inheritViewBox
                sx={{ fontSize: { xs: '40px', sm: '56px' }, cursor: 'pointer' }}
              />
              <Typography
                variant='body1'
                sx={{ fontWeight: '600', paddingBottom: '4px', color: 'white' }}
              >
                iOS App herunterladen
              </Typography>
            </Stack>
          </Link>
        </Stack>
      </Paper>
    </Fade>
  );
};
