import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { ErrorFallback, resetErrorBoundary } from 'components/error';
import { queryClient } from 'lib/react-query';
import { theme } from 'styles/theme';

import { msalInstance } from '../lib/authConfig';

type AppProviderProps = {
  children?: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <MsalProvider instance={msalInstance}>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary FallbackComponent={ErrorFallback} onReset={resetErrorBoundary}>
            <QueryClientProvider client={queryClient}>
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              <Router>{children}</Router>
            </QueryClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </RecoilRoot>
    </MsalProvider>
  );
};
