import { Skeleton, Typography } from '@mui/material';
import { Fragment } from 'react';

import { theme } from 'styles/theme';

import { HorizontalScroll } from '../HorizontalScroll';

export const HorizontalScrollLoading = () => {
  return (
    <HorizontalScroll disableControls>
      {[...Array(5)].map((e, i) => (
        <Fragment key={i}>
          <Skeleton
            variant='rectangular'
            width={theme.variables.episode.card.width}
            height={theme.variables.episode.card.height}
            sx={{
              mr: theme.variables.episode.card.mr,
              borderRadius: theme.variables.radius.base,
              mt: '5px',
              mb: '1rem',
            }}
          />
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            <Skeleton height='1.5rem' width='6rem' />
          </Typography>
          <Typography variant='body1'>
            <Skeleton height='1.5rem' width='10rem' />
          </Typography>
        </Fragment>
      ))}
    </HorizontalScroll>
  );
};
