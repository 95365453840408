import { Box, Skeleton, Typography } from '@mui/material';
import { Fragment } from 'react';

import { Image } from 'components/common';
import { Podcast } from 'models';
import { StyledImageSkeleton, StyledPre } from 'styles/components';
import { theme } from 'styles/theme';

import { CategoryInfo } from './CategoryInfo';

type PodcastInfoProps = {
  podcast?: Podcast;
  categoryName: string;
  isLoading: boolean;
};

export const PodcastInfo = ({ podcast, categoryName, isLoading }: PodcastInfoProps) => {
  return (
    <Fragment>
      {isLoading ? (
        <Box>
          <Typography variant='h3' sx={{ mb: '1rem' }}>
            <Skeleton sx={{ width: '9rem', height: '2.5rem' }} />
          </Typography>
          <StyledImageSkeleton aspectVariant='square' />
          <Typography variant='h4' sx={{ mt: '1rem' }}>
            <Skeleton sx={{ width: '7rem', height: '2rem' }} />
          </Typography>
          <Typography variant='body1' sx={{ mt: '0.5rem' }}>
            <Skeleton style={{ marginBottom: 6 }} />
            <Skeleton width='80%' />
          </Typography>
        </Box>
      ) : (
        <Fragment>
          <Typography variant='h3' sx={{ mb: '2rem' }}>
            Über den Podcast
          </Typography>
          <Image
            src={podcast?.image ?? ''}
            alt='Podcast Thumbnail'
            aspectVariant='square'
            borderRadius={theme.variables.radius.base}
            renderLoading={<StyledImageSkeleton aspectVariant='square' />}
            ctfWidth={500}
          />
          <Typography variant='h4' sx={{ mt: '1rem' }}>
            {podcast?.title ?? ''}
          </Typography>
          <StyledPre sx={{ mt: '0.5rem' }}>{podcast?.description ?? ''}</StyledPre>
          <CategoryInfo name={categoryName} />
        </Fragment>
      )}
    </Fragment>
  );
};
