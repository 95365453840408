import { Skeleton, Typography } from '@mui/material';
import { Fragment } from 'react';

import { GridContainer } from 'components/common';
import { theme } from 'styles/theme';

export const CategoryLoading = () => {
  return (
    <Fragment>
      <Typography variant='h3' sx={{ mb: '2rem' }}>
        <Skeleton variant='text' sx={{ width: '7rem' }} />
      </Typography>
      <GridContainer>
        {[...new Array(4)].map((v, i) => (
          <Skeleton
            key={i}
            variant='rectangular'
            sx={{ height: '20rem', borderRadius: theme.variables.radius.base }}
          />
        ))}
      </GridContainer>
    </Fragment>
  );
};
