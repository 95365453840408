/* eslint-disable react-hooks/exhaustive-deps */
import { useUpdateEffect } from 'ahooks';
import { RefObject } from 'react';
import ReactPlayer from 'react-player';
import { useRecoilState } from 'recoil';

import { useEpisodeTimestamp } from 'hooks';
import { triggerEpisodeEndedFirstTime } from 'lib/AnalyticsService';
import { Episode, Progress } from 'models';
import { defaultPlayer, playerState } from 'store';

import { useStep } from './useStep';
import { useVolume } from './useVolume';

export const usePlayer = (playerRef?: RefObject<ReactPlayer>) => {
  const [player, setPlayer] = useRecoilState(playerState);
  const { episodeTimestamp, setEpisodeTimestamp } = useEpisodeTimestamp(player.nowPlayingId ?? '');
  const { value: playbackRate, nextValue: nextPlaybackRate } = useStep({
    start: 1,
    min: 0.75,
    max: 2,
    step: 0.25,
  });
  const { volume, handleVolumeChange, handleVolumeOn, handleVolumeOff } = useVolume();

  // Resets player on episode change
  useUpdateEffect(() => {
    setPlayer(() => ({ ...defaultPlayer, nowPlayingId: player.nowPlayingId }));
  }, [player.nowPlayingId]);

  // Loads timestamp if episode was played before
  useUpdateEffect(() => {
    if (player.isReady && episodeTimestamp) {
      playerRef?.current && playerRef.current.seekTo(episodeTimestamp.played);
    }
  }, [player.isReady]);

  const isLoadingEpisode = (episodeId: Episode['id']) =>
    episodeId === player.nowPlayingId && player.isLoading;

  const isPlayingEpisode = (episodeId: Episode['id']) =>
    episodeId === player.nowPlayingId && player.isPlaying;

  const handleIsReady = () => setPlayer((prev) => ({ ...prev, isReady: true }));

  const handleClickPlay = (episodeId: Episode['id']) => {
    player.nowPlayingId !== episodeId
      ? setPlayer((prev) => ({ ...prev, nowPlayingId: episodeId }))
      : setPlayer((prev) => ({ ...prev, isPlaying: !prev.isPlaying }));
  };

  const handlePlayPause = () => {
    setPlayer((prev) => ({
      ...prev,
      isPlaying: !prev.isPlaying,
    }));
  };

  const handleProgress = (progress: Progress, episode: Episode) => {
    // We only want to update time slider if we are not currently seeking
    if (player.nowPlayingId && !player.isSeeking) {
      triggerEpisodeEndedFirstTime(episode, progress, episodeTimestamp);
      setEpisodeTimestamp({
        played: progress.played,
        playedSeconds: progress.playedSeconds,
      });
      setPlayer((prev) => ({ ...prev, progress: progress }));
    }
  };

  const handleSeekChange = (event: Event, value: number | Array<number>) => {
    setPlayer((prev) => ({
      ...prev,
      isSeeking: true,
      played: value,
      progress: {
        ...prev.progress,
        played: (value as number) / 100,
      },
    }));
  };

  const handleSeekUp = (event: React.SyntheticEvent | Event, value: number | Array<number>) => {
    setPlayer((prev) => ({
      ...prev,
      isSeeking: false,
      progress: {
        ...prev.progress,
        played: (value as number) / 100,
      },
    }));
    playerRef?.current && playerRef.current.seekTo((value as number) / 100);
  };

  const handleBufferStart = () => {
    setPlayer((prev) => ({ ...prev, isLoading: true }));
  };

  const handleBufferEnd = () => {
    setPlayer((prev) => ({ ...prev, isLoading: false }));
  };

  return {
    player,
    handleIsReady,
    playbackRate,
    nextPlaybackRate,
    handlePlayPause,
    handleProgress,
    handleSeekChange,
    handleSeekUp,
    handleBufferStart,
    handleBufferEnd,
    volume,
    handleVolumeChange,
    handleVolumeOn,
    handleVolumeOff,
    handleClickPlay,
    isLoadingEpisode,
    isPlayingEpisode,
  };
};
