import { Typography } from '@mui/material';

import { useCategoryPodcasts } from 'api';
import { HorizontalScrollLoading, HorizontalScrollTitleLoading } from 'components/scroll/loading';
import { StyledContainer } from 'styles/components';

import { DiscoverCategoryPodcasts } from './DiscoverCategoryPodcasts';

export const DiscoverCategories = () => {
  const { data: categoryPodcasts = [], isLoading } = useCategoryPodcasts();

  if (isLoading)
    return (
      <StyledContainer>
        <Typography fontWeight='bold' fontSize={32} variant='h4'>
          Kategorien
        </Typography>
        <HorizontalScrollTitleLoading withIcon />
        <HorizontalScrollLoading />
      </StyledContainer>
    );

  return (
    <StyledContainer id='categories'>
      <Typography fontWeight='bold' fontSize={32} variant='h4'>
        Kategorien
      </Typography>
      {categoryPodcasts.map((category) => (
        <DiscoverCategoryPodcasts key={category.id} categoryPodcasts={category} />
      ))}
    </StyledContainer>
  );
};
