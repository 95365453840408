// Import styled from emotion in order to use as child selector
import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

import { theme } from 'styles/theme';
import { excludeProps } from 'utils';

type StyledIconProps = {
  iconUrl: string;
};

export const StyledIcon = styled(
  (props: PropsWithChildren<BoxProps>) => (
    <Box sx={{ width: '2.5rem', height: '2.5rem' }} {...props}>
      {props.children}
    </Box>
  ),
  {
    shouldForwardProp: (prop) => excludeProps<StyledIconProps>(['iconUrl'], prop),
  }
)<StyledIconProps>(
  ({ iconUrl }) => `  
  border-radius: ${theme.variables.radius.icon};
  background-image: url(${iconUrl});
  background-repeat: no-repeat;
  background-size: contain;
  align-self: self-start;
  flex: none;
`
);
