import { CloseRounded } from '@mui/icons-material';
import {
  Breakpoint,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
} from '@mui/material';
import { ReactNode } from 'react';

import { useModal } from 'hooks/useModal';

export type BasicModalProps = {
  title: ReactNode;
  content: ReactNode;
  size?: false | Breakpoint;
  handleClose?: () => void;
};

const StyledContent = styled(DialogContent)`
  padding-top: 1rem !important;
`;

export const BasicModal = ({ title, content, handleClose, size = 'lg' }: BasicModalProps) => {
  const { hideModal } = useModal();

  const onClose = () => {
    handleClose && handleClose();
    hideModal();
  };

  return (
    <Dialog
      keepMounted={false}
      open
      onClose={onClose}
      fullWidth
      maxWidth={size}
      disableEscapeKeyDown
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center' pr={2}>
        <DialogTitle>{title}</DialogTitle>
        <IconButton onClick={onClose}>
          <CloseRounded />
        </IconButton>
      </Stack>
      <StyledContent>{content}</StyledContent>
    </Dialog>
  );
};
