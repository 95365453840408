export const DATE_FORMAT_DISPLAY = 'dd.MM.yyyy';

export const parseDate = (date: string | undefined) => {
  if (date) {
    // Date format from api 'dd-MM-yyyy HH:mm:ss';
    const [day, month, year, hour, minute, second] = date.split(/-|\s|:/);

    // Note: The month argument in the Date constructor is zero-indexed, so we need to subtract 1 from the month value.
    const dateObject = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hour),
      Number(minute),
      Number(second)
    );

    return dateObject;
  }
};
