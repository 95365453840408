import { alpha, Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

import { theme } from 'styles/theme';

type ScrollControlProps = {
  visible: boolean;
  children: ReactNode;
  onClick: () => void;
  sx: SxProps;
};

export const ScrollControl = ({ visible, children, onClick, sx }: ScrollControlProps) => {
  return (
    <Box
      sx={{
        display: visible ? 'none' : 'flex',
        alignItems: 'center',
        height: 'calc(100% - 4rem)',
        position: 'absolute',
        zIndex: 99,
        ...sx,
      }}
    >
      <Box
        onClick={onClick}
        sx={{
          display: 'flex',
          p: 1,
          backgroundColor: alpha(theme.palette.primary.main, 0.9),
          borderRadius: '50px',
          fill: theme.palette.background.paper,
          cursor: 'pointer',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
