import { Skeleton, SkeletonProps, styled } from '@mui/material';
import { CSSProperties } from 'react';

import { theme } from 'styles/theme';
import { AspectRatioVariant, excludeProps, getAspectRatioStyle } from 'utils';

type StyledImageSkeletonProps = {
  width?: CSSProperties['width'];
  aspectVariant?: AspectRatioVariant;
};

export const StyledImageSkeleton = styled(
  (props: SkeletonProps) => <Skeleton variant='rectangular' {...props} />,
  {
    shouldForwardProp: (prop) =>
      excludeProps<StyledImageSkeletonProps>(['width', 'aspectVariant'], prop),
  }
)<StyledImageSkeletonProps>(({ width = '100%', aspectVariant = 'wide' }) => ({
  borderRadius: theme.variables.radius.base,
  height: 'unset',
  transform: 'none',
  ...getAspectRatioStyle(width, aspectVariant),
}));
