/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Podcast App
 * REST APIs for Porsche Podcast iOS mobile application
 * OpenAPI spec version: 1.0.2
 */
import { customAxios } from '../lib/axios';

import type {
  GenericResponseCategoryEpisodesSuggestionResponse,
  GenericResponseCategoryPodcastsSuggestionResponse,
  GenericResponseCategoryResponse,
  GenericResponseFAQsResponse,
  GenericResponseFullTextSearchResponse,
  GenericResponseLatestEpisodesResponse,
  GenericResponsePodcastEpisodesResponse,
  GenericResponsePodcastResponse,
  GetContentfulEpisodesByPodcastUsingGETParams,
  GetContentfulFullTextSearchUsingGETParams,
  GetContentfulPodcastsByCategoryUsingGETParams,
} from './dtos';

export const getPodcastApp = () => {
  /**
   * @summary Get all categories stored in Contentful
   */
  const getContentfulCategoriesUsingGET = () => {
    return customAxios<GenericResponseCategoryResponse>({
      url: `/api/v1/categories`,
      method: 'GET',
    });
  };

  /**
   * @summary Get the suggested episodes for each category uploaded in Contentful
   */
  const getContentfulSuggestedCategoryEpisodesUsingGET = () => {
    return customAxios<GenericResponseCategoryEpisodesSuggestionResponse>({
      url: `/api/v1/category-episodes`,
      method: 'GET',
    });
  };

  /**
   * @summary Get the suggested podcasts for each category uploaded in Contentful
   */
  const getContentfulSuggestedCategoryPodcastsUsingGET = () => {
    return customAxios<GenericResponseCategoryPodcastsSuggestionResponse>({
      url: `/api/v1/category-podcasts`,
      method: 'GET',
    });
  };

  /**
   * @summary Get a single episode or all episodes or all episodes by stored in Contentful
   */
  const getContentfulEpisodesByPodcastUsingGET = (
    params?: GetContentfulEpisodesByPodcastUsingGETParams
  ) => {
    return customAxios<GenericResponsePodcastEpisodesResponse>({
      url: `/api/v1/episodes`,
      method: 'GET',
      params,
    });
  };

  /**
   * @summary Get all FAQs stored in Contentful
   */
  const getContentfulFAQsUsingGET = () => {
    return customAxios<GenericResponseFAQsResponse>({ url: `/api/v1/faqs`, method: 'GET' });
  };

  /**
   * @summary Get the latest episodes uploaded in Contentful
   */
  const getContentfulLatestEpisodesUsingGET = () => {
    return customAxios<GenericResponseLatestEpisodesResponse>({
      url: `/api/v1/latest-episodes`,
      method: 'GET',
    });
  };

  /**
   * @summary Get a single podcast or all podcasts or all podcasts by category stored in Contentful
   */
  const getContentfulPodcastsByCategoryUsingGET = (
    params?: GetContentfulPodcastsByCategoryUsingGETParams
  ) => {
    return customAxios<GenericResponsePodcastResponse>({
      url: `/api/v1/podcasts`,
      method: 'GET',
      params,
    });
  };

  /**
   * @summary Get podcast or episodes stored in Contentful, using full text search on selected fields
   */
  const getContentfulFullTextSearchUsingGET = (
    params: GetContentfulFullTextSearchUsingGETParams
  ) => {
    return customAxios<GenericResponseFullTextSearchResponse>({
      url: `/api/v1/search`,
      method: 'GET',
      params,
    });
  };

  /**
   * @summary Synchronize new content stored in Contentful
   */
  const getSyncUsingGET = () => {
    return customAxios<void>({ url: `/api/v1/sync`, method: 'GET' });
  };

  return {
    getContentfulCategoriesUsingGET,
    getContentfulSuggestedCategoryEpisodesUsingGET,
    getContentfulSuggestedCategoryPodcastsUsingGET,
    getContentfulEpisodesByPodcastUsingGET,
    getContentfulFAQsUsingGET,
    getContentfulLatestEpisodesUsingGET,
    getContentfulPodcastsByCategoryUsingGET,
    getContentfulFullTextSearchUsingGET,
    getSyncUsingGET,
  };
};
export type GetContentfulCategoriesUsingGETResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getPodcastApp>['getContentfulCategoriesUsingGET']>>
>;
export type GetContentfulSuggestedCategoryEpisodesUsingGETResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getPodcastApp>['getContentfulSuggestedCategoryEpisodesUsingGET']>
  >
>;
export type GetContentfulSuggestedCategoryPodcastsUsingGETResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getPodcastApp>['getContentfulSuggestedCategoryPodcastsUsingGET']>
  >
>;
export type GetContentfulEpisodesByPodcastUsingGETResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getPodcastApp>['getContentfulEpisodesByPodcastUsingGET']>>
>;
export type GetContentfulFAQsUsingGETResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getPodcastApp>['getContentfulFAQsUsingGET']>>
>;
export type GetContentfulLatestEpisodesUsingGETResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getPodcastApp>['getContentfulLatestEpisodesUsingGET']>>
>;
export type GetContentfulPodcastsByCategoryUsingGETResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getPodcastApp>['getContentfulPodcastsByCategoryUsingGET']>>
>;
export type GetContentfulFullTextSearchUsingGETResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getPodcastApp>['getContentfulFullTextSearchUsingGET']>>
>;
export type GetSyncUsingGETResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getPodcastApp>['getSyncUsingGET']>>
>;
