import { atom } from 'recoil';

import { BasicModalProps, MODAL_TYPES, OnboardModalProps, AppBannerProps } from 'components/modal';

export type BasicModalType = {
  modalType: typeof MODAL_TYPES.BasicModal;
  modalProps: BasicModalProps;
};

export type OnboardModalType = {
  modalType: typeof MODAL_TYPES.OnboardModal;
  modalProps?: OnboardModalProps;
};

export type AppBannerModalType = {
  modalType: typeof MODAL_TYPES.AppBanner;
  modalProps?: AppBannerProps;
};

export type ModalType = BasicModalType | OnboardModalType | AppBannerModalType;

export const modalState = atom<ModalType | null>({
  key: 'modalState',
  default: null,
});
