import type { Environment } from './environment.model';
import { baseEnvironment } from './environment.model';

/* eslint-disable no-template-curly-in-string */
export const environment: Environment = {
  ...baseEnvironment,
  build: '${BUILD}',
  release: '${BUILD}-release',
  environment: '${BUILD}',
  api: {
    url: process.env.REACT_APP_API ?? '',
    clientId: process.env.REACT_APP_API_CLIENT_ID ?? '',
    clientSecret: process.env.REACT_APP_API_CLIENT_SECRET ?? '',
  },
  tenant: 'porsche',
  msal: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_MSAL_AUTHORITY ?? '',
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL ?? '',
    postLogoutRedirectUri: process.env.REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URL ?? '',
    loginRequestScopes: `openid profile email offline_access`,
    browserLoggingEnabled: false,
    active: true,
    logLevel: process.env.REACT_APP_MSAL_LOG_LEVEL ?? 'info',
  },
};
