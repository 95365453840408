import React, { Fragment } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { ReactComponent as ArrowHeadRightIcon } from 'assets/icon/arrow-head-right.svg';

import { ScrollControl } from './ScrollControl';

export const ScrollNextArrow = () => {
  const { isLastItemVisible, getNextElement, scrollToItem, initComplete } =
    React.useContext(VisibilityContext);

  return initComplete ? (
    <ScrollControl
      visible={isLastItemVisible}
      onClick={() => scrollToItem(getNextElement(), 'smooth', 'end')}
      sx={{ right: '2rem' }}
    >
      <ArrowHeadRightIcon />
    </ScrollControl>
  ) : (
    <Fragment></Fragment>
  );
};
