import { InteractionType, InteractionStatus } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { CircularProgress, Stack } from '@mui/material';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AnalyticsService } from 'lib/AnalyticsService';
import { Landing } from 'pages';

import { environment } from '../environments';
import { getUserId, loginRequest } from '../lib/authConfig';

import { protectedRoutes } from './protectedRoutes';

const { msal } = environment;

export const AppRoutes = () => {
  const { inProgress } = useMsal();
  useMsalAuthentication(InteractionType.Silent, loginRequest);

  const userId = getUserId();

  useEffect(() => {
    if (userId) AnalyticsService.setUserId(userId);
  }, [userId]);

  return msal.active ? (
    <>
      <AuthenticatedTemplate>
        <Routes>
          {protectedRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {inProgress !== InteractionStatus.None ? (
          <Stack alignItems='center' sx={{ marginTop: '5rem' }}>
            <CircularProgress />
          </Stack>
        ) : (
          <Landing />
        )}
      </UnauthenticatedTemplate>
    </>
  ) : (
    <Routes>
      {protectedRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};
