import { useQuery } from 'react-query';

import { queryClient, queryKeys } from 'lib/react-query';
import { GetContentfulPodcastsByCategoryUsingGETParams, getPodcastApp } from 'proxy';
import { toPodcast } from 'services';

export const getPodcasts = (params: GetContentfulPodcastsByCategoryUsingGETParams) =>
  getPodcastApp().getContentfulPodcastsByCategoryUsingGET(params);

export const usePodcasts = (categoryId: string) => {
  return useQuery(
    queryKeys.category.detail(categoryId),
    () => getPodcasts({ category_id: categoryId }),
    {
      initialData: queryClient.getQueryData(queryKeys.category.detail(categoryId)),
      initialDataUpdatedAt: queryClient.getQueryState(queryKeys.category.detail(categoryId))
        ?.dataUpdatedAt,
      select: (data) => data.data?.map(toPodcast),
      enabled: !!categoryId,
    }
  );
};
